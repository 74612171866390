import { T } from '@sonnen/shared-i18n/customer';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Logo,
  MediaQuery,
  TransformIconType,
} from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { getPaths } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { Header, NavToggle } from '+components';
import { CONFIG } from '+config';
import { history } from '+router/store';

import './DemoHeader.component.scss';

const REGISTRATION_URL = `${CONFIG.AUTH.AUTH_URL}/registration`;

const renderButtons = () => (
  <div className={'c-demo-header__buttons'}>
    <div className={'c-demo-header__button-primary'}>
      <Button
        dataTestId={'demo-header-register-button'}
        label={I18n.t(T.demo.header.register)}
        size={ButtonSize.NORMAL}
        theme={ButtonTheme.OUTLINE_PRIMARY}
        onClick={() => window.location.assign(REGISTRATION_URL)}
      />
    </div>
    <Button
      dataTestId={'demo-header-leave-demo-button'}
      label={I18n.t(T.demo.header.leave)}
      size={ButtonSize.NORMAL}
      theme={ButtonTheme.TRANSPARENT}
      onClick={() => history.push(getPaths().LOGIN)}
    />
  </div>
);

const renderExpandableNavigation = (isNavExpanded: boolean) => (
  <Media query={MediaQuery.DOWN_NAV}>
    <div className={'c-demo-header__nav'}>
      {isNavExpanded && (
        <div className={'c-demo-header__nav-overlay'} />
      )}
      <div className={classNames('c-demo-header__nav-content', {
        'is-open': isNavExpanded,
      })}>
        <div className={'c-demo-header__nav-description'}>
          {I18n.t(T.demo.header.description)}
        </div>
        {renderButtons()}
      </div>
    </div>
  </Media>
);

const renderLogo = () => (
  <div className={'c-demo-header__logo'}>
    <Logo />
    <span className={'c-demo-header__logo-label'}>
      {I18n.t(T.demo.header.logoLabel)}
    </span>
  </div>
);

export const DemoHeader: React.FC = () => {
  const [isNavExpanded, setNavExpandedState] = React.useState(false);
  const toggleNav = () => setNavExpandedState(!isNavExpanded);

  return (
    <div className={'c-demo-header'}>
      <Header
        redirectTo={RouteName.DEMO_DASHBOARD}
        logo={renderLogo()}
      >
        <Media query={MediaQuery.UP_NAV}>
          {(isDesktop: boolean) =>
            isDesktop ? (
              renderButtons()
            ) : (
              <NavToggle
                type={isNavExpanded
                  ? TransformIconType.X
                  : TransformIconType.MENU}
                onClick={toggleNav}
              />
            )}
        </Media>
      </Header>
      {renderExpandableNavigation(isNavExpanded)}
    </div>
  );
};
