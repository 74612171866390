import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

// @TODO Change button to shared web one after designers align on common version

export const GuidePurchaseProposalFooter: React.FC<{
  onCheckOffer?: () => void;
}> = ({ onCheckOffer }) => (
  <div className={'c-guide-purchase-step-accept-offer__offer-group-footer'}>
    {onCheckOffer ? (
      <button
        className={'c-guide-purchase-step-accept-offer__button'}
        onClick={onCheckOffer}
      >
        {I18n.t(T.customerGuidance.purchase.step.acceptOffer.offer.cta)}
      </button>
    ) : (
      <div className={'c-guide-purchase-step-accept-offer__no-offer'}>
        {I18n.t(T.customerGuidance.purchase.flatOffer.noOffer)}
      </div>
    )}
  </div>
);
