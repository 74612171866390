import { Icon } from '@sonnen/shared-web';
import * as React from 'react';

import './DownloadLink.component.scss';

interface Props {
  title: React.ReactNode;
  onDownload?: () => void;
}

export const DownloadLink: React.FC<Props> = ({
  title,
  onDownload,
}) => (
  <div className={'c-download-link'} >
    <div className={'c-download-link__label'} >
      {title}
    </div>
    <button
      className={'c-download-link__button'}
      onClick={onDownload}
    >
      <Icon.Eye
        className={'c-download-link__icon'}
        hasHoverAnimation={true}
      />
    </button>
  </div>
);
