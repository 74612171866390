import { T } from '@sonnen/shared-i18n/customer';
import { Icon, Link, Loader, useCountryFeature } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { BatteryHelper } from '+battery/store/battery.helpers';
import { Battery, BatteryControllerType } from '+battery/store/types/battery.interface';
import { CountryFeatureName } from '+config/countryFlags';
import { SpecsItem } from '+shared/components';

import './BatterySpecsTech.component.scss';

interface Props {
  batteryData: Battery | undefined;
}

export const BatterySpecsTech: React.SFC<Props> = ({ batteryData }) => {
  const batteryPower = BatteryHelper.getInverterPower(batteryData);
  const warrantyPeriod = BatteryHelper.getWarrantyPeriod(batteryData);
  const batteryProductName = BatteryHelper.getBatteryProductName(batteryData);
  const isSpreeBattery = batteryData?.controllerType === BatteryControllerType.SPREE;
  const isBatteryCountryReadyForReleaseNote = useCountryFeature(CountryFeatureName.RELEASE_NOTES).isEnabled;
  const isReadyForReleaseNote = isBatteryCountryReadyForReleaseNote && isSpreeBattery;

  if (!batteryData) {
    return <Loader />;
  }

  return (
    <div className={'c-battery-specs-tech'}>
      {batteryProductName && (
        <SpecsItem
          dataTestId={'sonnen-batterie-specs-tech-product-name'}
          title={I18n.t(T.batterySystem.overview.details.model)}
          value={batteryProductName}
        />
      )}
      {batteryData.serialNumber && (
        <SpecsItem
          dataTestId={'sonnen-batterie-specs-tech-serial-number'}
          title={I18n.t(T.batterySystem.overview.details.serialNumber)}
          value={batteryData.serialNumber}
        />
      )}
      {(batteryProductName || batteryData.serialNumber) && (
        <div className={'c-battery-specs-tech__divider'} />
      )}
      {batteryData.batteryInverterDesignPower && (
        <SpecsItem
          dataTestId={'sonnen-batterie-specs-tech-max-output-power'}
          title={I18n.t(T.batterySystem.details.sections.specs.parts.maxOutputPower)}
          value={batteryPower}
        />
      )}
      {batteryData.batteryModules && (
        <SpecsItem
          dataTestId={'sonnen-batterie-specs-tech-number-of-modules'}
          title={I18n.t(T.batterySystem.details.sections.specs.parts.numberOfModules)}
          value={batteryData.batteryModules}
      />
      )}
      {(batteryData.batteryInverterDesignPower || batteryData.batteryModules) && (
        <div className={'c-battery-specs-tech__divider'} />
      )}
      {batteryData.softwareVersion && (
        <SpecsItem
          dataTestId={'sonnen-batterie-specs-tech-software-version'}
          title={I18n.t(T.batterySystem.details.sections.specs.parts.softwareVersion)}
          value={(
            <div>
              <span>{batteryData.softwareVersion}</span>
              {isReadyForReleaseNote && (
                <>
                  <br />
                  <Link
                    dataTestId={'sonnen-batterie-specs-tech-check-release-note-link'}
                    label={I18n.t(T.batterySystem.details.checkReleaseNote.title)}
                    href={I18n.t(T.batterySystem.details.checkReleaseNote.link)}
                    icon={<Icon.Angle className={'c-battery-specs-tech__link-arrow'} />}
                  />
                </>
              )}
            </div>
          )}
        />
      )}
      <SpecsItem
        dataTestId={'sonnen-batterie-specs-tech-technology'}
        title={I18n.t(T.batterySystem.details.sections.specs.parts.batteryTechnology)}
        value={I18n.t(T.batterySystem.details.sections.specs.parts.batteryTechnologyType)}
      />
      {/* @TODO: Show 0 charge cycles as well when SB10 supports it. */}
      {!!batteryData.batteryChargeCycles && (
        <SpecsItem
          dataTestId={'sonnen-batterie-specs-tech-charge-cycles'}
          title={I18n.t(T.batterySystem.details.sections.specs.parts.chargeCycles)}
          value={batteryData.batteryChargeCycles}
        />
      )}
      {warrantyPeriod && (
        <SpecsItem
          dataTestId={'sonnen-batterie-specs-tech-guarantee'}
          title={I18n.t(T.batterySystem.details.sections.specs.parts.guarantee)}
          value={warrantyPeriod}
        />
      )}
    </div>
  );
};
