import { T } from '@sonnen/shared-i18n/customer';
import { Button, FormCheckbox, Icon, Link, Logo } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { GuideActions } from '+app/+guide/store/+guide.actions';
import { getAuthError } from '+app/+guide/store/+guide.selectors';
import { NetworkCode } from '+app/shared/network/network.interface';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';

import './GuideVerifyIdentity.component.scss';

const mapStateToProps = (state: StoreState) => ({
  error: getAuthError(state),
});

const mapDispatchToProps = mapActions({
  verifyIdentity: GuideActions.verifyIdentity,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

// @TODO: I18n
export const GuideVerifyIdentityComponent: React.FC<Props> = ({ actions, error }) => {
  const [zipCode, changeZipCode] = React.useState('');
  const [isVerificationSaved, saveVerification] = React.useState(false);

  const isForbidden = !!error
    && error.response.status === NetworkCode.FORBIDDEN;

  const zipCodeHandler: React.ChangeEventHandler<HTMLInputElement> = e =>
    changeZipCode(e.currentTarget.value);

  const submitForm: React.FormEventHandler = e => {
    e.preventDefault();
    actions.verifyIdentity(zipCode, isVerificationSaved);
  };

  return (
    <div className={'c-guide-verify-identity'}>
      <div className={'c-guide-verify-identity__header'}>
        <Logo />
      </div>
      <form
        className={'c-guide-verify-identity__wrapper'}
        onSubmit={submitForm}
      >
        {isForbidden ? (
          <>
            <div className={'c-guide-verify-identity__error-icon'}>
              <Icon.PaperPlaneError className={'c-guide-verify-identity__error-icon-svg'} />
            </div>
            <h1 className={'c-guide-verify-identity__title'}>
              {I18n.t(T.customerGuidance.identityVerification.forbidden.title)}
            </h1>
            <h3 className={'c-guide-verify-identity__headline'}>
              {`${I18n.t(T.customerGuidance.identityVerification.forbidden.headline)}: `}
              <Link
                href={'tel:+4908009293340'}
                label={'+49 0800 929 33 40'}
              />
            </h3>
          </>
        ) : (
          <>
            <h1 className={'c-guide-verify-identity__title'}>
              {I18n.t(T.customerGuidance.identityVerification.title)}
            </h1>
            <h3 className={'c-guide-verify-identity__headline'}>
              {I18n.t(T.customerGuidance.identityVerification.headline)}
            </h3>
            <input
              type={'text'}
              placeholder={I18n.t(T.customerGuidance.identityVerification.zipCodeInput.placeholder)}
              value={zipCode}
              onChange={zipCodeHandler}
              className={classNames('c-guide-verify-identity__input', {
                'is-error': !!error,
              })}
            />
            {!!error && (
              <p className={'c-guide-verify-identity__error'}>
                {I18n.t(T.customerGuidance.identityVerification.zipCodeInput.error)}
              </p>
            )}
            <div className={'c-guide-verify-identity__checkbox'}>
              <FormCheckbox
                checked={isVerificationSaved}
                name={'saveVerification'}
                label={I18n.t(T.customerGuidance.identityVerification.checkbox.label)}
                onChange={() => saveVerification(!isVerificationSaved)}
              />
            </div>
            <Button
              type={'submit'}
              label={I18n.t(T.customerGuidance.identityVerification.submit.label)}
            />
          </>
        )}
      </form>
    </div>
  );
};

export const GuideVerifyIdentity = connect(mapStateToProps, mapDispatchToProps)(GuideVerifyIdentityComponent);
