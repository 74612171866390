import * as R from 'ramda';
import * as packageJson from '../../package.json';
import { Env, isMobile } from './environment';

export interface AppConfiguration {
  APP_NAME: string;
  VERSION: string;
  SENTRY_DSN: string;
  DEVELOPMENT_MODE: boolean;
  AUTHORIZED_DOMAINS: string[];
  IS_MOBILE: boolean;
  SONNEN_DRIVE_LINK: string;
}

export const getAppConfig = (env: Env): AppConfiguration => ({
  APP_NAME: 'CustomerPortal',
  VERSION: packageJson.version,
  SENTRY_DSN: 'https://56a8355fa3a946978f9f9b865e0a2e97@sentry.sonnenbatterie.de/10',
  DEVELOPMENT_MODE: R.includes(window.location.hostname, ['localhost', '0.0.0.0']),
  AUTHORIZED_DOMAINS: ['sonnen.de', 'sonnenbatterie.de', 'sonnen.it',
    ...(env === Env.DEVELOPMENT ? ['execute-api.eu-central-1.amazonaws.com'] : []),
    ...(env === Env.LOCAL ? ['localhost:4000'] : []),
  ],
  IS_MOBILE: isMobile,
  SONNEN_DRIVE_LINK: 'https://sonnendrive.de/?token=ea54bcb887a0837c647fc165d4ea734b32b1234f',
});
