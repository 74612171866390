import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

import { insertIf } from '+app/utils/array.util';
import { Configuration } from '+guide/store/types';
import { ConfigurationHelper } from '+shared/helpers/configuration.helper';

export const factorizeConfDetails = ({
   market,
   powerPlant,
   excessTariffGrossPerKwh,
   initialAdvancePaymentGrossPerMonth,
   cashbackTariffGrossPerKwh,
}: Configuration) => {
  const currency = ConfigurationHelper.getCurrencyForMarket(market);

  return [
    // Monthly pre-payment
    {
      name: `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.monthlyPrepayment)}:`,
      value: `${ConfigurationHelper.formatMeasurementPrice(initialAdvancePaymentGrossPerMonth, currency)}/${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.month)}`,
    },
    // Excess energy
    {
      name: `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.excessEnergy)}:`,
      value: `${ConfigurationHelper.formatMeasurementEnergy(powerPlant.expectedExcessEnergyPerYear)}/${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.year)}`,
      isLight: true,
    },
    // Excess tariff
    {
      name: `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.excessTariff)}:`,
      value: ConfigurationHelper.formatPricePerKwh(excessTariffGrossPerKwh, currency, { asMinorUnit: true }),
      isLight: true,
    },
    // Expected cashback
    {
      name: `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.expectedCashback)}:`,
      value: `+${ConfigurationHelper.formatMeasurementPrice(powerPlant.expectedCashbackGrossPerYear, currency)}/${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.year)}`,
    },
    // Expected unused free usage allowance
    {
      name: `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.expectedUnusedFreeUsageAllowance)}:`,
      value: `${ConfigurationHelper.formatMeasurementEnergy(powerPlant!.expectedUnusedUsageAllowancePerYear)}/${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.year)}`,
      isLight: true,
    },
    // Cashback rate
    {
      name: `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.cashbackRate)}:`,
      value: ConfigurationHelper.formatPricePerKwh(cashbackTariffGrossPerKwh, currency, { asMinorUnit: true }),
      isLight: true,
    },
    // VPP bonus
    ...insertIf(
      !!powerPlant.guaranteedVppBonusGranted,
      {
        name: I18n.t(T.customerGuidance.purchase.flatOffer.vppBonus),
        value: powerPlant.guaranteedVppBonusGranted && powerPlant.guaranteedVppBonusGrossPerYear
          ? `${I18n.t(T.customerGuidance.purchase.flatOffer.minimum)} ${ConfigurationHelper.formatMeasurementPrice(powerPlant.guaranteedVppBonusGrossPerYear, currency)}/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`
          : '',
      },
    ),
  ];
};
