import { CONFIG } from '+config';
import { useHttp, useJsonApi } from '+shared/network';
import {
  CessionDocumentData,
  CessionDocumentStatusData,
  ConfigurationData,
  DocumentData,
  ImpactAnalysisData,
  LeadData,
  OfferData,
} from './types';
import { FlatDocumentType } from './types/document.interface';
import { PartnerData } from './types/partner.interface';

const BASE = `${CONFIG.API.SALES}`; // v1
// @NOTE LS_BASE is Sales Solution's Lead Service endpoints url.
// LS is a service allowing operations, caching etc. on leads without doing constant requests to SF
const LS_BASE = `${CONFIG.API.SALES_LS}`; // rc

const verifyToken = (zipCode: string) => useHttp()
  .post<{ token: string }>(`${BASE}/auth/customer-guidance/verify-token`, {
    body: {
      data: {
        type: 'lead',
        attributes: {
          zipCode,
        },
      },
    },
  })
  .then(r => r.parsedBody());

const revokeToken = () => useHttp()
  .post(`${BASE}/auth/customer-guidance/revoke-token`, { body: {} });

const getLeadCollection = () => useJsonApi()
  .getList<LeadData>(`${LS_BASE}/leads`)
  .send();

const getLead = (leadId: string) => useJsonApi()
  .get<LeadData>(`${LS_BASE}/leads/${leadId}`)
  .send();

const getLeadPartner = (leadId: string) => useJsonApi()
  .get<PartnerData>(`${BASE}/leads/${leadId}/partner`)
  .send();

const getOffer = (leadId: string, offerId: string) => useJsonApi()
  .get<OfferData>(`${BASE}/leads/${leadId}/offers/${offerId}`)
  .send();

const getOfferCollection = (leadId: string) => useJsonApi()
  .getList<OfferData>(`${BASE}/leads/${leadId}/offers`)
  .send();

const getConfiguration = (leadId: string, configurationId: string) => useJsonApi()
  .get<ConfigurationData>(`${BASE}/leads/${leadId}/configurations/${configurationId}`)
  .send();

const getHardwareOfferCollection = (leadId: string, offerId: string) => useJsonApi()
  .getList<DocumentData>(`${BASE}/leads/${leadId}/offers/${offerId}/hardware-offers`)
  .send();

const postOfferDocument = (
  leadId: string,
  offerId: string,
  flatOfferType: FlatDocumentType,
) => useJsonApi()
  .post(
    `${BASE}/leads/${leadId}/offers/${offerId}/documents/${flatOfferType}`,
  )
  .ofType('documents')
  .send();

const getFlatOfferFile = (flatOfferType: FlatDocumentType) => (
  leadId: string,
  offerId: string,
  documentId: string,
) => useHttp()
  .get<string>(
    `${BASE}/leads/${leadId}/offers/${offerId}/documents/${flatOfferType}/${documentId}/file`,
    {},
  )
  .then(res => res.parsedBody());

const getHardwareOfferFile = (
  leadId: string,
  offerId: string,
  documentId: string,
) => useHttp()
  .get<string>(
    `${BASE}/leads/${leadId}/offers/${offerId}/hardware-offers/${documentId}/file`,
    {},
  )
  .then(res => res.parsedBody());

const getImpactAnalysisCollection = (leadId: string) => useJsonApi()
  .getList<ImpactAnalysisData>(`${BASE}/leads/${leadId}/impact-analysis`)
  .send();

const getImpactAnalysisFile = (
  leadId: string,
  impactAnalysisId: string,
  documentId: string,
) => useHttp()
  .get<string>(
    `${BASE}/leads/${leadId}/impact-analysis/${impactAnalysisId}/documents/${documentId}/file`,
    {},
  )
  .then(res => res.parsedBody());

const getOrderConfirmationDocument = (
  leadId: string,
) => useHttp()
  .get<string>(
    `${BASE}/leads/${leadId}/contract/documents/order-confirmation/file`,
    {},
  )
  .then(res => res.parsedBody());

const getSignedCessionDocument = (
  leadId: string,
) => useHttp()
  .get<string>(
    `${BASE}/leads/${leadId}/contract/documents/cession/file`,
    {},
  )
  .then(res => res.parsedBody());

const getCessionDoc = (leadId: string) =>
  useJsonApi()
    .get<CessionDocumentData>(`${BASE}/leads/${leadId}/cession`)
    .send();

const getCessionDocStatus = (leadId: string) =>
  useJsonApi()
    .get<CessionDocumentStatusData>(`${BASE}/leads/${leadId}/cession/status`)
    .send();

export const GuideRepository = {
  verifyToken,
  revokeToken,
  getLeadCollection,
  getLead,
  getLeadPartner,
  getOffer,
  getOfferCollection,
  getConfiguration,
  getHardwareOfferCollection,
  getFlatOfferFile,
  getHardwareOfferFile,
  getImpactAnalysisCollection,
  getImpactAnalysisFile,
  postOfferDocument,
  getOrderConfirmationDocument,
  getSignedCessionDocument,
  getCessionDoc,
  getCessionDocStatus,
};
