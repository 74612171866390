import { omit } from 'lodash/fp';
import { combineReducers } from 'redux';

import { queryReducer } from '+shared/store/query/query.reducer';
import { createReducer } from '+utils/redux';
import { GUIDE_ACTIONS, GuideActions } from './+guide.actions';
import {
  GET_CESSION_DOCUMENT,
  GET_CESSION_STATUS,
  GET_FLAT_OFFER_FILE_QUERY,
  GET_HARDWARE_OFFER_FILE_QUERY,
  GET_IMPACT_ANALYSIS_LIST_QUERY,
  GET_LEAD_QUERY,
  GET_OFFERS_QUERY,
  GET_ORDER_CONFIRMATION_FILE_QUERY,
  GET_PARTNER_QUERY,
  GUIDE_SYNC_QUERY,
  GuideAuthState,
  GuideCommonState,
  initialAuthState,
  initialCommonState,
} from './+guide.state';
import { Offer, OfferProductStatus, OfferStatus } from './types';
import { FlatOfferAccepted, LeadStage, LeadStatusName } from './types/leadStatus.interface';

import * as acceptance from '+guide/+acceptance/store';
import * as discovery from '+guide/+discovery/store';
import * as overview from '+guide/+overview/store';
import * as setup from '+guide/+setup/store';
import { isFlatProduct } from './+guide.helper';

const insertIntoOffers = (
  offers: Offer[],
  offerIndex: number,
  newItem: object,
) => [
    ...offers.slice(0, offerIndex),
    newItem,
    ...offers.slice(offerIndex + 1),
  ];

const authReducer = createReducer<GuideAuthState, GuideActions>(
  (state = initialAuthState, action) => {
    switch (action.type) {
      case GUIDE_ACTIONS.SET_AUTH_TOKEN:
        return {
          ...state,
          token: action.token,
        };
      case GUIDE_ACTIONS.SET_VERIFIED_AUTH_TOKEN:
        return action.leadId ? {
          ...state,
          persistedTokens: {
            ...state.persistedTokens,
            [action.leadId]: action.token,
          },
        } : {
            ...state,
            verifiedToken: action.token,
          };
      case GUIDE_ACTIONS.REQUIRE_IDENTITY_VERIFICATION:
        return {
          ...state,
          verifyingIdentity: true,
        };
      case GUIDE_ACTIONS.IDENTITY_VERIFIED:
        return {
          ...state,
          verifyingIdentity: false,
          error: undefined,
        };
      case GUIDE_ACTIONS.VERIFY_IDENTITY_ERROR:
        return {
          ...state,
          error: action.error,
        };
      case GUIDE_ACTIONS.REVOKE_TOKEN:
        return {
          ...state,
          token: undefined,
          persistedTokens: {
            ...omit(action.leadId)(state.persistedTokens),
          },
        };
      default:
        return queryReducer(state, action, []);
    }
  },
  {
    persist: {
      key: 'guide-auth',
      whitelist: ['persistedTokens'],
    },
  },
);

const commonReducer = createReducer<GuideCommonState, GuideActions>(
  (state = initialCommonState, action) => {
    switch (action.type) {
      case GUIDE_ACTIONS.SET_PHASE_ACTIVE_TAB:
        return {
          ...state,
          phaseActiveTab: action.activeTab,
        };
      case GUIDE_ACTIONS.SET_LEAD:
        return {
          ...state,
          lead: action.lead,
        };
      case GUIDE_ACTIONS.SET_LEAD_ID:
        return {
          ...state,
          lead: {
            ...state.lead,
            id: action.leadCollection[0] ? action.leadCollection[0].id : undefined,
            source: action.leadCollection[0] ? action.leadCollection[0].source : undefined,
          },
        };
      case GUIDE_ACTIONS.SET_PARTNER:
        return {
          ...state,
          partner: action.partner,
        };
      case GUIDE_ACTIONS.SET_OFFERS:
        return {
          ...state,
          offers: action.offers,
        };
      case GUIDE_ACTIONS.SET_IMPACT_ANALYSIS_LIST:
        return {
          ...state,
          impactAnalysisList: action.impactAnalysisList,
        };
      case GUIDE_ACTIONS.SET_OFFER_ACCEPTED_STATUS:
        const index = state.offers.findIndex(o => o.id === action.offerId);
        const flatProductIndex = state.offers[index].products.findIndex(isFlatProduct);

        // the status has to be sent on an offer level and product within offer
        const flatProduct = {
          ...state.offers[index].products[flatProductIndex],
          status: OfferProductStatus.ACCEPTED,
        };
        const offer = {
          ...state.offers[index],
          status: OfferStatus.BINDINGLY_ACCEPTED,
          products: [
            ...state.offers[index].products.slice(0, flatProductIndex),
            flatProduct,
            ...state.offers[index].products.slice(flatProductIndex + 1),
          ],
        };
        const flatOfferAccepted: FlatOfferAccepted = {
          stage: LeadStage.OFFER_ACCEPTED,
          name: LeadStatusName.FLAT_OFFER_ACCEPTED,
          createdAt: new Date(),
          isSet: true,
        };

        return {
          ...state,
          lead: {
            ...state.lead,
            status: {
              ...state.lead!.status,
              currentStatus: flatOfferAccepted,
              summary: {
                ...state.lead!.status.summary,
                flatOfferAccepted,
              },
            },
          },
          offers: [
            ...state.offers.slice(0, index),
            offer,
            ...state.offers.slice(index + 1),
          ],
        };
      case GUIDE_ACTIONS.SET_HARDWARE_OFFERS:
        return {
          ...state,
          hardwareOffers: {
            ...state.hardwareOffers,
            [action.offerId]: action.documents,
          },
        };
      case GUIDE_ACTIONS.SET_FLAT_OFFERS:
        const offerIndex = state.offers.findIndex(o => o.id === action.offerId);
        const newItem = {
          ...state.offers[offerIndex],
          flatOffers: action.documents,
        };
        return {
          ...state,
          offers: insertIntoOffers(
            state.offers,
            offerIndex,
            newItem,
          ),
        };
      case GUIDE_ACTIONS.SET_FLAT_CONFIGURATIONS:
        const offerIdx = state.offers.findIndex(o => o.id === action.offerId);
        const itemToAdd = {
          ...state.offers[offerIdx],
          flatConfigurations: action.documents,
        };
        return {
          ...state,
          offers: insertIntoOffers(
            state.offers,
            offerIdx,
            itemToAdd,
          ),
        };
      case GUIDE_ACTIONS.SET_DOCUMENT_URL:
        return {
          ...state,
          documentUrl: action.url,
        };
      case GUIDE_ACTIONS.SET_ORDER_CONFIRMATION_STATUS:
        return {
          ...state,
          orderConfirmationStatus: action.status,
        };
      case GUIDE_ACTIONS.SET_CESSION_DOCUMENT_STATUS:
        return {
          ...state,
          cessionDocumentStatus: action.status,
        };
      case GUIDE_ACTIONS.CLEAR_DOCUMENT_URL:
        return {
          ...state,
          documentUrl: undefined,
        };
      case GUIDE_ACTIONS.STOP_DOCUMENT_POLLING:
        return {
          ...state,
          documentUrl: undefined,
        };
      case GUIDE_ACTIONS.START_DOCUMENT_POLLING:
        return {
          ...state,
          documentUrl: undefined,
        };
      case GUIDE_ACTIONS.SET_TAX_OFFICE_REGISTRATION:
        return {
          ...state,
          lead: {
            ...state.lead,
            status: {
              ...state.lead!.status,
              summary: {
                ...state.lead!.status.summary,
                taxOfficeRegistrationCompleted: {
                  createdAt: action.data.createdAt,
                  isSet: action.data.isSet,
                  name: action.data.name,
                  stage: action.data.stage,
                },
              },
            },
          },
        };
      case GUIDE_ACTIONS.SET_BNETZA_REGISTRATION:
        return {
          ...state,
          lead: {
            ...state.lead,
            status: {
              ...state.lead!.status,
              summary: {
                ...state.lead!.status.summary,
                bNetzARegistrationCompleted: {
                  createdAt: action.data.createdAt,
                  isSet: action.data.isSet,
                  name: action.data.name,
                  stage: action.data.stage,
                },
              },
            },
          },
        };
      case GUIDE_ACTIONS.SET_CESSION_DOCUMENT:
        return {
          ...state,
          cessionDocUrl: action.cessionDocUrl,
        };
      case GUIDE_ACTIONS.SET_CESSION_STATUS:
        return {
          ...state,
          cessionStatus: action.status,
          cessionTimestamps: action.timestamps,
        };
      case GUIDE_ACTIONS.SET_CESSION_FAILURE_STATUS:
        return {
          ...state,
          cessionFailed: action.cessionFailed,
        };
      default:
        return queryReducer(state, action, [
          GUIDE_SYNC_QUERY,
          GET_LEAD_QUERY,
          GET_OFFERS_QUERY,
          GET_PARTNER_QUERY,
          GET_HARDWARE_OFFER_FILE_QUERY,
          GET_FLAT_OFFER_FILE_QUERY,
          GET_IMPACT_ANALYSIS_LIST_QUERY,
          GET_ORDER_CONFIRMATION_FILE_QUERY,
          GET_CESSION_DOCUMENT,
          GET_CESSION_STATUS,
        ]);
    }
  },
);

export const reducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  acceptance: acceptance.reducer,
  overview: overview.reducer,
  setup: setup.reducer,
  discovery: discovery.reducer,
});
