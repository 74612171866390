import { StatisticsV1 } from '+analysis/store/types/statisticsV1.interface';
import {
  ActionsUnion,
  createAction,
  createRoutine,
} from '+utils/redux';
import { Contract, ContractDocuments } from './types/contract.interface';
import { ContractFileRequest } from './types/contractFilesRequestData.interface';
import { ContractInvoiceRequest } from './types/contractInvoicesRequestData.interface';

export enum CONTRACT_ACTIONS {
  GET_CONTRACT_DATA = '[Contract] GET_CONTRACT_DATA',
  SET_CONTRACT_DATA = '[Contract] SET_CONTRACT_DATA',
  GET_SONNENFLAT_STATISTICS = '[Contract] GET_SONNENFLAT_STATISTICS',
  GET_SONNENFLAT_STATISTICS_FROM_LAST_YEAR = '[Contract] GET_SONNENFLAT_STATISTICS_FROM_LAST_YEAR',
  SET_SONNENFLAT_STATISTICS_DAILY = '[Contract] SET_SONNENFLAT_STATISTICS_DAILY',
  SET_SONNENFLAT_STATISTICS_TOTAL = '[Contract] SET_SONNENFLAT_STATISTICS_TOTAL',
  SET_SONNENFLAT_STATISTICS_FROM_LAST_YEAR = '[Contract] SET_SONNENFLAT_STATISTICS_FROM_LAST_YEAR',
  GET_CONTRACT_FILES_REQUEST_DATA = '[Contract] GET_CONTRACT_FILES_REQUEST_DATA',
  SET_CONTRACT_FILES_REQUEST_DATA = '[Contract] SET_CONTRACT_FILES_REQUEST_DATA',
  GET_CONTRACT_INVOICES_REQUEST_DATA = '[Contract] GET_CONTRACT_INVOICES_REQUEST_DATA',
  SET_CONTRACT_INVOICES_REQUEST_DATA = '[Contract] SET_CONTRACT_INVOICES_REQUEST_DATA',

  SET_METER_INSTALLATION_ID = '[Contract] SET_METER_INSTALLATION_ID',

  CLEAR_CONTRACT = '[Contract] CLEAR_CONTRACT',

  START_CONTRACTS_POLLING = '[Contract] START_CONTRACTS_POLLING',
  STOP_CONTRACTS_POLLING = '[Contract] STOP_CONTRACTS_POLLING',

  TERMINATE_CONTRACT = '[Contract] TERMINATE_CONTRACT',
  GET_CONTRACT_DOCUMENTS = '[Contract] GET_CONTRACT_DOCUMENTS',
  SET_CONTRACT_DOCUMENTS = '[Contract] SET_CONTRACT_DOCUMENTS',
  GET_CONTRACT_DOCUMENT_URL = '[Contract] GET_CONTRACT_DOCUMENT_URL',
  SET_CONTRACT_DOCUMENT_URL = '[Contract] SET_CONTRACT_DOCUMENT_URL',
  CLEAR_SELECTED_DOCUMENT_DATA = '[Contract] CLEAR_SELECTED_DOCUMENT_DATA',
}

export const ContractActions = {
  getContractData: () => createAction(CONTRACT_ACTIONS.GET_CONTRACT_DATA),
  setContractData: (contracts: Contract[]) => createAction(CONTRACT_ACTIONS.SET_CONTRACT_DATA, { contracts }),
  getSonnenFlatStatistics: () => createAction(CONTRACT_ACTIONS.GET_SONNENFLAT_STATISTICS),
  getSonnenFlatStatisticsFromLastYear: () => createAction(CONTRACT_ACTIONS.GET_SONNENFLAT_STATISTICS_FROM_LAST_YEAR),

  setSonnenFlatStatisticsDaily: (statistics: StatisticsV1) =>
    createAction(CONTRACT_ACTIONS.SET_SONNENFLAT_STATISTICS_DAILY, { daily: statistics }),
  setSonnenFlatStatisticsTotal: (statistics: StatisticsV1) =>
    createAction(CONTRACT_ACTIONS.SET_SONNENFLAT_STATISTICS_TOTAL, { total: statistics }),
  setSonnenFlatStatisticsFromLastYear: (statistics: StatisticsV1) =>
    createAction(CONTRACT_ACTIONS.SET_SONNENFLAT_STATISTICS_FROM_LAST_YEAR, { lastYear: statistics }),

  getContractFilesRequestData: (contractId: string) =>
    createAction(CONTRACT_ACTIONS.GET_CONTRACT_FILES_REQUEST_DATA, { contractId }),
  setContractFilesRequestData: ({
    contractId,
    contractFilesRequestData,
  }: { contractId: string, contractFilesRequestData: ContractFileRequest[] }) =>
    createAction(CONTRACT_ACTIONS.SET_CONTRACT_FILES_REQUEST_DATA, { contractId, contractFilesRequestData }),

  getContractInvoicesRequestData: (contractId: string) =>
    createAction(CONTRACT_ACTIONS.GET_CONTRACT_INVOICES_REQUEST_DATA, { contractId }),
  setContractInvoicesRequestData: ({ 
    contractId, 
    contractInvoicesRequestData,
  }: { contractId: string, contractInvoicesRequestData: ContractInvoiceRequest[] }) =>
    createAction(CONTRACT_ACTIONS.SET_CONTRACT_INVOICES_REQUEST_DATA, { contractId, contractInvoicesRequestData }),

  clearContract: () => createAction(CONTRACT_ACTIONS.CLEAR_CONTRACT),

  startContractsPolling: () => createAction(CONTRACT_ACTIONS.START_CONTRACTS_POLLING),
  stopContractsPolling: () => createAction(CONTRACT_ACTIONS.STOP_CONTRACTS_POLLING),

  terminateContract: (contractId: string) =>
    createAction(CONTRACT_ACTIONS.TERMINATE_CONTRACT, { contractId }),
  getContractDocuments: () =>
    createAction(CONTRACT_ACTIONS.GET_CONTRACT_DOCUMENTS),
  setContractDocuments: (documents: ContractDocuments[]) =>
    createAction(CONTRACT_ACTIONS.SET_CONTRACT_DOCUMENTS, { documents }),
  getContractDocumentUrl: (documentId: string) =>
    createAction(CONTRACT_ACTIONS.GET_CONTRACT_DOCUMENT_URL, { documentId }),
  setContractDocumentUrl: (documentUrl: string) =>
    createAction(CONTRACT_ACTIONS.SET_CONTRACT_DOCUMENT_URL, { documentUrl }),
  clearSelectedDocumentData: () =>
    createAction(CONTRACT_ACTIONS.CLEAR_SELECTED_DOCUMENT_DATA),
};

export type ContractActions = ActionsUnion<typeof ContractActions>;

export enum ContractActionType {
  SAVE_CONTRACT = 'CONTRACT/SAVE_CONTRACT',
  YOUR_FLAT = 'CONTRACT/YOUR_FLAT/GET_BAR_DATA',
}

export const contractSagaRoutine = createRoutine(
  ContractActionType.SAVE_CONTRACT,
);

export type ContractSagaRoutine =
  | ReturnType<typeof contractSagaRoutine.success>
  | ReturnType<typeof contractSagaRoutine.failure>;

export const yourFlatRoutine = createRoutine(ContractActionType.YOUR_FLAT);

export type YourFlatRoutine =
  | ReturnType<typeof yourFlatRoutine.success>
  | ReturnType<typeof yourFlatRoutine.failure>;

export type ContractAction = ContractSagaRoutine | YourFlatRoutine;
