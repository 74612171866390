import { T } from '@sonnen/shared-i18n/customer';
import { breakpointUp, ExpandableSection } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

interface Props {
  toggleDetails?: () => void;
  isExpanded?: boolean;
}

export const GuidePurchaseAccordion: React.FC<Props> = ({ toggleDetails, isExpanded, children }) => (
  <Media query={{ minWidth: breakpointUp('XS') }}>
    {(isDesktop: boolean) => isDesktop
      ? children
      : (
        <ExpandableSection
          buttonLabel={I18n.t(T.customerGuidance.purchase.flatOffer.moreDetails)}
          buttonLabelWhenExpanded={I18n.t(T.customerGuidance.purchase.flatOffer.hideDetails)}
          maxHeight={1500}
          controlledToggle={toggleDetails}
          isExpanded={isExpanded}
        >
          {children}
        </ExpandableSection>
      )
    }
  </Media>
);
