import { Data, MergedData, Relationship } from '@coolio/json-api';
import { CountryCode } from '@sonnen/shared-web';

export enum ContractStatus {
  ACTIVATED = 'Activated',
  CONCLUDED = 'Concluded',
  DRAFT = 'Draft',
  CANCELLED = 'Cancelled',
  TERMINATED = 'Terminated',
  TERMINATED_CONTRACT_CHANGE = 'TerminatedContractChange',
}

// TODO fix contract types by adding all possible api versions of contract type names (`sonnenflat`, `sonnenFlat`, etc)
export enum ContractTypes {
  SONNEN_FLAT_IT = 'sonnenFlatIT',
  SONNEN_FLAT_HOME = 'sonnenFlat',
  SONNEN_FLAT_X = 'energyGlobal',
  SONNEN_STORM = 'sonnenStrom',
  SONNEN_ENERGY = 'energy',
  SONNEN_CONNECT_AU = 'sonnenConnectAu',
  SONNEN_CONTROL = 'control',
}

export enum TariffTypes {
  START = 'start',
  SOLAR = 'solar',
  PLUS = 'plus',
  SONNEN_BATTERIE_HOME_AT = 'sonnenbatterieHome-AT',
  SONNEN_BATTERIE_HOME_DE = 'sonnenbatterieHome-DE',
  SONNEN_ENERGY_IT = 'sonnenEnergy-IT',
  SONNEN_FLAT_1500_IT = 'sonnenFlat1500-IT',
}

export enum MeterInstallationPreparationTypes {
  NOT_NEEDED = 'not_needed',
  MISSING = 'missing',
  SUBMITTED = 'submitted',
  IN_CLEARING = 'in_clearing',
  CHECKED = 'checked',
}

export enum MeterChangeStatus {
  SUCCESSFUL = 'successful',
  ABORTED = 'aborted',
}

export enum ContractFlatXCessionStatus {
  INACTIVE = 'inactive',
  ACTIVATED = 'activated',
  DEACTIVATED_METER_SHUTDOWN = 'deactivated - meter shutdown',
  DEACTIVATED_CHANGED_TO_DIRECT_MARKETING = 'deactivated - changed to direct marketing',
  DEACTIVATED_CONTRACT_TERMINATED = 'deactivated - contract terminated',
}

export enum ContractFlatXCessionStatusMarker {
  SIGNED_BY_CUSTOMER = 'signed by customer',
  SENT_TO_DSO = 'sent to DSO',
  CONFIRMED_BY_DSO = 'confirmed by DSO',
  DSO_CLEARING = 'DSO clearing',
  PAYMENTS_RUNNING = 'payments running',
}

export enum ContractFlatXCessionDsoClearingReason {
  DIFFERENT_PLANT_OPERATOR = 'different plant operator',
  GENERAL_REJECTION = 'general rejection',
  HAS_NOT_BEEN_COMMERCIALLY_BUILT_UP_YET = 'has not been commercially built up yet',
  MISSING_CUSTOMER_INFORMATION = 'missing customer information',
  MORE_PLANT_OPERATORS = 'more plant operators',
  NO_VALID_ESIGNATURE = 'no valid eSignature',
  OTHER_CUSTOMER_RECORD_TYPE = 'other customer record type',
  OTHER_REASON = 'other reason',
  PLANT_CANT_BE_IDENTIFIED = 'plant can’t be identified',
  THIRD_PARTY_CESSION = 'third party cession',
  WRONG_DSO = 'wrong DSO',
}

export enum FinancialServiceProvider {
  LUMENAZA = 'Lumenaza',
  ZUORA = 'Zuora',
  ENERSIS = 'Enersis',
}

export type ContractUsageAllowanceFlatXConditions = {
  hasReceivedSignedDeclaration: boolean;
  hasLEWDSO: boolean;
  hasDeclarationBeenSentToDistribution: boolean;
  cantAllocatePVSystem: boolean;
  hasMissingCustomerInformation: boolean;
  hasInvalidEsignature: boolean;
  hasRejectedDeclarations: boolean;
  cantAcceptAssignment: boolean;
  isAssignmentProcessed: boolean;
  isConfirmedByDso: boolean;
  hasPaymentRunning: boolean;
  hasDeactivatedStatusChangedToDirectMarketing: boolean;
};

export interface ContractAttributes {
  contractNumber: string;
  contractType: ContractTypes;
  cessionStatusMarker: ContractFlatXCessionStatusMarker | null;
  cessionStatus: ContractFlatXCessionStatus | null;
  cessionDsoClearingReason: ContractFlatXCessionDsoClearingReason | null;
  cessionFirCessionAccepted: boolean | null;
  contractPreviousNumber: string | null;
  tariffType: TariffTypes | null;
  startAt: string;
  activatedAt: string;
  deliveryStartAt: string | null;
  endAt: string;
  status: ContractStatus | null;
  annualConsumption: number | null;
  appointedEnergyRate: number | string | null;
  meterFstCheckSuccessful?: string | null;
  meterInstallation?: {
    changeNotificationToDso: boolean;
    preparationStatus: MeterInstallationPreparationTypes | null;
    resultMeterChange: MeterChangeStatus | null;
  } | null;
  meterProcessStatus: string | null;
  migrationViaCp: boolean | null;
  name: string;
  orderedAt: string | null;
  pvAssetEnergyProductionVerification: 'not_successful' | 'successful' | 'in_progress' | null;
  annualConsumptionTotal: number | null;
  amountOfFreeEnergy: number | null;
  communityFee: number | null;
  billingStreet: string | null;
  billingStreetNumber: string | null;
  billingPostalCode: string;
  billingCity: string;
  billingState: string | null;
  billingCountryCode: string;
  salesforceContractId: string;
  shippingStreet: string | null;
  shippingStreetNumber: string | null;
  shippingPostalCode: string;
  shippingCity: string;
  shippingState: string | null;
  shippingCountryCode: CountryCode | null;
  contractingPartySalutation: string | null;
  contractingPartyAcademicTitle: string | null;
  contractingPartnerFirstname: string;
  contractingPartnerLastname: string;
  contractingPartyEmail: string | null;
  contractingPartyPhone: string | null;
  contractingPartyBirthday: string | null;
  contractingPartyDatevNo: string | null;
  bankFirstname: string | null;
  bankLastname: string | null;
  bankIban: string | null;
  bankSwift: string | null;
  bankSepaDueAt: string | null;
  usageAllowanceActivatedOn: string | null;
  meterInstallationId: string | null;
  financialServiceProvider: FinancialServiceProvider | null;
  terminationRequested: boolean;
}

export type ContractRelationships = {
  meterInstallation: Relationship;
};

export type ContractData = Data<ContractAttributes>;

export type Contract = MergedData<ContractData>;

export enum ContractDocumentType {
  CESSION = 'CESSION',
  ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
}

export interface ContractDocumentUrlAttributes {
  id: string;
  url: string;
}

export type ContractDocumentUrlData = Data<ContractDocumentUrlAttributes>;
export type ContractDocumentUrl = MergedData<ContractDocumentUrlData>;

interface ContractDocumentsAttributes {
  createdAt: string;
  type: ContractDocumentType;
  version: string;
  filename: string;
  id: string;
}

export type ContractDocumentsData = Data<ContractDocumentsAttributes>;
export type ContractDocuments = MergedData<ContractDocumentsData>;
