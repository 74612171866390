import { QueryState } from '+query/query.state';
import { T } from '@sonnen/shared-i18n/customer';
import { EnergyUnit, Measurement } from '@sonnen/shared-web';
import { divide, isArray, isNil, mapValues, toLower } from 'lodash/fp';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';

import { DateUtil, TimePeriod } from '+app/utils/date.util';
import { Contract, ContractStatus } from '+dashboard/+contract/store/types/contract.interface';
import { Consumption, ExpectedConsumption } from '+legacy/components/charts/AnnualConsumptionChart/AnnualConsumptionChart.types';
import { getFormattedDate } from '+legacy/helpers/dates';
import { linearRegression } from '+legacy/helpers/estimations';
import { parseNumber } from '+legacy/helpers/numbers';
import { StatisticsData } from '+shared/types/statisticsData.interface';
import { ContractTypes, TariffTypes } from '../types/contract.interface';
import {
  getBillingPeriodForContractTypeSonnenFlatHome,
  getContractSonnenFlatHomeUsageByDay,
  getPercentageUsageForContractTypeSonnenFlatHome,
  getProductDetailsListForContractTypeSonnenFlatHome,
  getUsageAllowanceForSonnenFlatHomeForCurrentPeriod,
} from './contractFlatHome.helpers';
import {
  getBillingPeriodForContractTypeSonnenFlatX,
  getContractSonnenFlatXUsageByDay,
  getPercentageUsageForContractTypeSonnenFlatX,
  getProductDetailsListForContractTypeSonnenFlatX,
  getUsageAllowanceForSonnenFlatX,
} from './contractFlatX.helpers';

interface ContractDetailsConfig {
  label: string;
  value: string;
}

/* all contracts helpers */

const isContractType = (contract: Contract | undefined, contractType: ContractTypes): boolean => {
  return contract?.contractType
    ? contract.contractType.toLowerCase() === contractType.toLowerCase()
    : false;
};

const isTariffType = (contract: Contract | undefined, tariffType: TariffTypes): boolean => {
  return contract?.tariffType
    ? contract.tariffType.toLowerCase() === tariffType.toLowerCase()
    : false;
};

const isContractStartingCurrentYear = (contractStartDate: string): boolean =>
  contractStartDate
    ? moment(contractStartDate).isSame(moment(), 'year')
    : false;

const isContractActive = (contract: Contract | undefined) =>
  contract?.deliveryStartAt &&
  moment()
    .startOf('day')
    .diff(contract.deliveryStartAt) > 0; // NOTE: Date in past

const THIRTY_MINUTES = DateUtil.getOneHourInMiliseconds() * 0.5;

const isEnergyDocumentRequestDataValid = (
  timestamp: moment.Moment | undefined,
) => timestamp && moment().diff(timestamp) < THIRTY_MINUTES;

const isFreeUsageAllowanceValueMissing = (
  freeUsageAllowance: number | null,
  isSiteWithBattery: boolean,
  queryStatus: QueryState,
) => (!freeUsageAllowance || !isSiteWithBattery) && !queryStatus.pending;

const selectCurrentContract = (contracts: Contract[]) => {
  const activeContracts = contracts.filter(contract => contract.status === ContractStatus.ACTIVATED);
  const energyGlobal = activeContracts.find(contract => (
    contract.contractType === ContractTypes.SONNEN_FLAT_X
  ));

  if (activeContracts.length === 1) {
    return activeContracts[0];
  }
  return energyGlobal || activeContracts[0];
};

/* Contract Energy helpers */

const getEnergyProductDetailsItems = (
  contractType: string,
  contractNumber: string,
  orderedAt: string | null,
  dataTestId?: string,
) => [
  {
    label: I18n.t(T.yourFlat.tableLabels.first),
    value:
      toLower(contractType) === toLower(ContractTypes.SONNEN_FLAT_X)
        ? I18n.t(T.yourFlat.sonnenFlatX.name)
        : contractType,
    dataTestId: `${dataTestId}-product-name`,
  },
  {
    label: I18n.t(T.yourFlat.tableLabels.third),
    value: contractNumber,
    dataTestId: `${dataTestId}-contract-number`,
  },
  ...isNil(orderedAt) ? [] : [{
    label: I18n.t(T.yourFlat.tableLabels.forth),
    // TODO: GET RID OF String()
    value: String(getFormattedDate(orderedAt)),
    dataTestId: `${dataTestId}-ordered-at`,
  }],
];

/* Contract sonnenTariff helpers */

const isContractFreePeriodActive = (orderedAt: string) => moment()
  .diff(moment(orderedAt), 'days') < DateUtil.getYearDaysAmount(orderedAt);

/* Contract sonnenFlat helpers */

const calculateSonnenFlatSavings = (
  currentConsumption = 0,
  flatQuota = 0,
) => {
  const rates = I18n.t(T.yourFlat.values.rates._all);
  const {
    default: defaultRate = 0,
    exceeded = 0,
    reducedExceeded = 0,
  } = mapValues(parseNumber, rates);

  const exceededAmount = Math.max(0, currentConsumption - flatQuota);
  const reducedExceedAmount = Math.max(0, exceededAmount - 2000);

  return (
    (currentConsumption - exceededAmount) * defaultRate +
    (exceededAmount - reducedExceedAmount) * exceeded +
    reducedExceedAmount * reducedExceeded
  );
};

const hasVpp = (contract: Contract | undefined): boolean =>
  isContractType(contract, ContractTypes.SONNEN_FLAT_HOME) &&
  isTariffType(contract, TariffTypes.SONNEN_BATTERIE_HOME_DE);

const getUsageAllowancePeriod = (contract: Contract, year: number): TimePeriod => {
  const usageAllowanceStartDate = contract.usageAllowanceActivatedOn;
  const isFirstYearOfContract = moment(usageAllowanceStartDate).year() === year;

  if (isFirstYearOfContract) {
    return {
      start: moment(usageAllowanceStartDate),
      end: moment(usageAllowanceStartDate).endOf('year'),
    };
  }

  return {
    start: moment(year).startOf('year'),
    end: moment(year).endOf('year'),
  };
};

const mapStatisticsToEnergyUsage = (statistics: Measurement, startDate: number): Consumption =>
  statistics.map((value: any, index: number) => ({
    value,
    timestamp: moment(startDate).add(index + 1, 'days').valueOf(),
    unit: EnergyUnit.KW,
  })) as Consumption;

const getBillingPeriodUTCTimestampsInTimezone = ({ start, end }: TimePeriod): { start: number, end: number } => {
  // TODO figure out a way to stop momentjs from logging errors -> try to load moment-timezone-with-data-10...
  const startTimezone = start.zoneName();
  const endTimezone = end.zoneName();

  return {
    start: moment.utc(start.tz(startTimezone).format(DateUtil.DateFormat.NUMERIC_FULL_DATE)).valueOf(),
    end: moment.utc(end.tz(endTimezone).format(DateUtil.DateFormat.NUMERIC_FULL_DATE)).valueOf(),
  };
};

const calculateExpectedUsage = ({
  dataSeries,
  currentTotalUsage,
  maxTotalUsage,
  billingPeriod,
}: {
  dataSeries: Measurement<number>,
  currentTotalUsage: number,
  maxTotalUsage: number,
  billingPeriod: TimePeriod,
}) => {
  const { start, end } = getBillingPeriodUTCTimestampsInTimezone(billingPeriod);
  if (dataSeries.length < 30) {
    return mapEnergyValues(
      [0, Math.max(dataSeries.slice(-1)[0] || 0, currentTotalUsage, maxTotalUsage)],
      [start, end],
    );
  }

  const xValue = moment(end).diff(moment(start), 'days');
  const xValues = new Array(dataSeries.length).fill(0).map((_, i) => i + 1);
  const predictedValue = linearRegression(xValues, dataSeries)(xValue);

  return mapEnergyValues([0, predictedValue], [start, end]);
};

const getConsumptionDailyFromStatistics = (statistics: StatisticsData | undefined): number[] =>
  (statistics?.daily && getAccumulatedFlatValues(statistics.daily.consumedEnergy)) || [];

const getGridPurchaseTotalFromStatistics = (statistics: StatisticsData | undefined): number =>
  // TODO make sure if we could receive 'number | number[]'
  (statistics?.total && statistics.total.gridPurchaseEnergy[0]) || 0;

const getGridPurchaseDailyFromStatistics = (statistics: StatisticsData | undefined): number[] =>
  (statistics?.daily && getAccumulatedFlatValues(statistics.daily.gridPurchaseEnergy)) || [];

type SonnenFlatDataProvider = {
  getBillingPeriod: () => TimePeriod;
  getContractDetails: () => Array<{ label: string, value: string }>;
  getEnergyUsageDaily: () => Measurement<number>;
  getExpectedUsage: () => ExpectedConsumption;
  getFreeUsageAllowance: () => number | null;
  getPercentageUsage: () => number;
  getContractUsageDaily: () => Consumption;
  contract: Contract,
};

const createSonnenFlatDataProvider: (contractData: {
  batteryTimezone?: string,
  contract: Contract,
  sonnenFlatStatistics: StatisticsData | undefined,
}) => SonnenFlatDataProvider = ({
  batteryTimezone = moment.tz.guess(),
  contract,
  sonnenFlatStatistics,
}) => {
  let billingPeriod: TimePeriod;
  let freeUsageAllowance: number | null;
  let freeUsageAllowanceForFirstYear: number | null;
  let energyUsageDaily: number[];

  const isSonnenFlatXContract = isContractType(contract, ContractTypes.SONNEN_FLAT_X);

  const getBillingPeriod = (): TimePeriod => {
    if (!billingPeriod) {
      billingPeriod = isSonnenFlatXContract
        ? getBillingPeriodForContractTypeSonnenFlatX(batteryTimezone, contract)
        : getBillingPeriodForContractTypeSonnenFlatHome(batteryTimezone, contract)
      ;
    }

    return billingPeriod;
  };

  const getFreeUsageAllowance = (): number | null => {
    if (!freeUsageAllowance) {
      freeUsageAllowance = isSonnenFlatXContract
        ? getUsageAllowanceForSonnenFlatX(contract, moment().year())
        : getUsageAllowanceForSonnenFlatHomeForCurrentPeriod(contract)
      ;
    }

    return freeUsageAllowance;
  };

  const getFreeUsageAllowanceForFirstYear = (): number | null => {
    const firstYear = contract.usageAllowanceActivatedOn
      ? moment(contract.usageAllowanceActivatedOn).year()
      : null;

    if (!freeUsageAllowanceForFirstYear) {
      freeUsageAllowanceForFirstYear = isSonnenFlatXContract
        ? getUsageAllowanceForSonnenFlatX(contract, firstYear)
        : getUsageAllowanceForSonnenFlatHomeForCurrentPeriod(contract)
      ;
    }

    return freeUsageAllowanceForFirstYear;
  };

  const getPercentageUsage = (): number => isSonnenFlatXContract
    ? getPercentageUsageForContractTypeSonnenFlatX(getFreeUsageAllowance(), sonnenFlatStatistics)
    : getPercentageUsageForContractTypeSonnenFlatHome(getFreeUsageAllowance(), sonnenFlatStatistics)
  ;

  const getContractUsageDaily = (): Consumption => isSonnenFlatXContract
    ? getContractSonnenFlatXUsageByDay(getBillingPeriod(), getEnergyUsageDaily())
    : getContractSonnenFlatHomeUsageByDay(getBillingPeriod(), getEnergyUsageDaily())
  ;

  const getContractDetails = (): Array<{ label: string, value: string }> => isSonnenFlatXContract
    ? getProductDetailsListForContractTypeSonnenFlatX(
        contract,
        getFreeUsageAllowanceForFirstYear(),
      )
    : getProductDetailsListForContractTypeSonnenFlatHome(contract, getFreeUsageAllowance())
  ;

  const getExpectedUsage = (): ExpectedConsumption => getContractSonnenFlatExpectedUsage(
    getBillingPeriod(),
    getEnergyUsageDaily(),
    getFreeUsageAllowance() || 0,
    getCurrentTotalUsage(),
  );

  const getEnergyUsageDaily = (): Measurement<number> => {
    if (!energyUsageDaily) {
      energyUsageDaily = isSonnenFlatXContract
        ? getGridPurchaseDailyFromStatistics(sonnenFlatStatistics)
        : getConsumptionDailyFromStatistics(sonnenFlatStatistics)
      ;
    }

    return energyUsageDaily;
  };

  const getCurrentTotalUsage = (): number => isSonnenFlatXContract
    ? getGridPurchaseTotalFromStatistics(sonnenFlatStatistics)
    : contract.annualConsumption || 0
  ;

  return {
    getBillingPeriod,
    getContractDetails,
    getContractUsageDaily,
    getEnergyUsageDaily,
    getExpectedUsage,
    getFreeUsageAllowance,
    getPercentageUsage,
    contract,
  };
};

const isExceedingExpected = (expectedUsage: ExpectedConsumption, freeUsageAllowance: number): boolean => {
  const expectedUsageEndValue = expectedUsage[1].value;

  return expectedUsageEndValue > freeUsageAllowance;
};

const isQuotaExceeded = (
  contractUsageDaily: Measurement<number>,
  freeUsageAllowance: number,
): boolean => {
  const newestEnergyUsage = contractUsageDaily.slice(-1)[0];

  return newestEnergyUsage > freeUsageAllowance;
};

const getContractSonnenFlatExpectedUsage = (
  billingPeriod: TimePeriod,
  consumptionDaily: Measurement<number>,
  freeUsageAllowance: number,
  currentTotalUsage: number,
) => {
  return calculateExpectedUsage({
    dataSeries: consumptionDaily,
    currentTotalUsage,
    maxTotalUsage: freeUsageAllowance,
    billingPeriod,
  });
};

const getAccumulatedFlatValues = (values?: Measurement) =>
  isArray(values) &&
  values.reduce(
    (result, value) => [
      ...result,
      divide(value || 0, 1000) + (result[result.length - 1] || 0),
    ],
    [0],
  );

const mapEnergyValues = (
  values: [number, number],
  dates: [number, number],
) => values.map((value, index) => ({
  value,
  timestamp: dates[index],
  unit: EnergyUnit.KW,
}));

const hasContractDeliveryStarted = (contract: Contract) =>
  contract && !isNil(contract.deliveryStartAt)
    ? moment(new Date(contract.deliveryStartAt)).isBefore(new Date())
    : false;

const isContractUsageAllowanceActivated = (contract: Contract) =>
  contract && !isNil(contract.usageAllowanceActivatedOn)
    ? moment(new Date(contract.usageAllowanceActivatedOn)).isBefore(new Date())
    : false;

const getContractActiveSteps = (contract: Contract) => ({
  contractOrdered: true,
  hardwareInstallation: isContractType(contract, ContractTypes.SONNEN_FLAT_HOME),
  supplyByEnergy: true,
  energyActivated: true,
});

const getContractTotalStepsCount = (contract: Contract) =>
  Object.values(getContractActiveSteps(contract)).filter(isActive => isActive).length;

const getContractStepsCount = (contract: Contract, hasInstalledBattery: boolean) =>
    1 +
    (Number(hasInstalledBattery) && Number(isContractType(contract, ContractTypes.SONNEN_FLAT_HOME))) +
    Number(hasContractDeliveryStarted(contract)) +
    Number(isContractUsageAllowanceActivated(contract));

const isContractStatusDone = (contract: Contract, hasInstalledBattery: boolean) =>
  getContractStepsCount(contract, hasInstalledBattery) === getContractTotalStepsCount(contract);

export {
  ContractDetailsConfig,
  SonnenFlatDataProvider,
  calculateSonnenFlatSavings,
  isContractActive,
  createSonnenFlatDataProvider,
  getBillingPeriodUTCTimestampsInTimezone,
  getConsumptionDailyFromStatistics,
  getEnergyProductDetailsItems,
  getGridPurchaseDailyFromStatistics,
  getGridPurchaseTotalFromStatistics,
  getUsageAllowancePeriod,
  hasVpp,
  isContractFreePeriodActive,
  isContractStartingCurrentYear,
  isContractType,
  isEnergyDocumentRequestDataValid,
  isExceedingExpected,
  isFreeUsageAllowanceValueMissing,
  isTariffType,
  isQuotaExceeded,
  mapStatisticsToEnergyUsage,
  hasContractDeliveryStarted,
  isContractUsageAllowanceActivated,
  getContractActiveSteps,
  getContractTotalStepsCount,
  getContractStepsCount,
  isContractStatusDone,
  selectCurrentContract,
};
