import * as React from 'react';

import './GuidePurchaseFlatOfferSection.component.scss';

interface Props {
  title: React.ReactNode;
}

export const GuidePurchaseFlatOfferSection: React.FC<Props> = ({ title, children }) => (
  <div className={'c-guide-purchase-flat-offer-section'}>
    <div className={'c-guide-purchase-flat-offer-section__title'}>
      {title}
    </div>
    {children}
  </div>
);
