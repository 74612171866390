import * as classNames from 'classnames';
import * as React from 'react';

import './GuideAcceptanceDetailsLine.component.scss';

interface Props {
  isMainValue?: boolean;
  isLight?: boolean;
  name: string;
  value: string;
}

export const GuideAcceptanceDetailsLine: React.FC<Props> = ({ isMainValue, isLight, name, value }) => (
  <div className={classNames('c-guide-acceptance-details-line', {
    'c-guide-acceptance-details-line--big': isMainValue,
    'c-guide-acceptance-details-line--light': isLight,
  })}>
    <div className={'c-guide-acceptance-details-line__name'}>{name}</div>
    <div className={classNames('c-guide-acceptance-details-line__value', {
      'c-guide-acceptance-details-line__main-value': isMainValue,
    })}>{value}</div>
  </div>
);
