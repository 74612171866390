import { XYPoint } from '@kanva/charts';
import { T } from '@sonnen/shared-i18n/customer';
import {
  AnalysisChartColors,
  AnalysisTooltipSymbolKind,
  ChartTooltipStyles,
  EnergyFlowSeriesKey,
  EnergyUnit,
  FUTURE_DATA_SERIES_KEYS,
  NumberUnit,
  OtherUnit,
  SeriesKey,
  StatisticsSeriesKey,
  TimeHelper,
} from '@sonnen/shared-web';
import { isNil, isNumber } from 'lodash';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';

import { BATTERY_CARE_LINE_COLOR } from '+analysis/components/AnalysisAreaChart/AnalysisAreaChart.helper';
import { formatNumberRangeWithUnit, formatNumberWithUnit } from '+app/utils/number.util';

import { KanvaTooltipValues } from '../kanva/kanva.types';

const getUnixDateFromNormalizedX = (normalizedX: number, unixStartOfDay: number) =>
  normalizedX * 60 + unixStartOfDay;

const shouldDisplayDataSeries = (
  key: SeriesKey,
  point: XYPoint | undefined,
  normalizedX: number | undefined,
  date: moment.Moment,
) => {
  const unixNow = TimeHelper.getUnixFromDate(moment());

  return !isNil(point) && !!normalizedX
    && (FUTURE_DATA_SERIES_KEYS.includes(key)
      ? getUnixDateFromNormalizedX(normalizedX, date.startOf('day').unix()) > unixNow
      : true);
};

export const getVisibleDataSeriesKeys = (
  selectedDate: moment.Moment,
  tooltipValues: KanvaTooltipValues | undefined,
  selectedDataSeriesKeys: SeriesKey[],
): SeriesKey[] => tooltipValues
  ? Object.entries(tooltipValues).filter(([key, value]) => {
    return selectedDataSeriesKeys.includes(key as SeriesKey) &&
      shouldDisplayDataSeries(
        key as SeriesKey,
        value,
        value?.x || 0,
        selectedDate,
      );
  }).map(([key, value]) => key) as SeriesKey[]
  : [];

export const seriesKeyTranslationMap = (key: string) => ({
  // AREA CHART
  [EnergyFlowSeriesKey.CONSUMPTION_POWER]: I18n.t(T.history.chart.labels.consumption),
  [EnergyFlowSeriesKey.PRODUCTION_POWER]: I18n.t(T.history.chart.labels.production),
  [EnergyFlowSeriesKey.BATTERY_USOC]: I18n.t(T.history.chart.labels.battery_usoc),
  [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: I18n.t(T.history.chart.labels.direct_usage_power),
  [EnergyFlowSeriesKey.GRID_FEEDIN]: I18n.t(T.history.chart.labels.grid_feedin),
  [EnergyFlowSeriesKey.GRID_PURCHASE]: I18n.t(T.history.chart.labels.grid_purchase),
  [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: I18n.t(T.forecast.chart.labels.consumption),
  [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: I18n.t(T.forecast.chart.labels.production),
  batteryCare: I18n.t(T.yourDay.chart.labels.batteryCare),

  // BAR CHART
  [StatisticsSeriesKey.CONSUMED_ENERGY]: I18n.t(T.history.chart.labels.consumption),
  [StatisticsSeriesKey.PRODUCED_ENERGY]: I18n.t(T.history.chart.labels.production),
})[key];

export const createUnitTransform = (
  precision: number,
  unit: EnergyUnit | OtherUnit,
  numberUnit?: NumberUnit,
) => (value: number | [number, number]) => {
  return isNumber(value)
    ? formatNumberWithUnit(precision, unit, numberUnit)(value)
    : formatNumberRangeWithUnit(unit)(value);
};

const transformWToKw = createUnitTransform(1, EnergyUnit.KW, NumberUnit.KILO);
const transformWhToKwh = createUnitTransform(1, EnergyUnit.KWH, NumberUnit.KILO);
const transformPercent = createUnitTransform(0, OtherUnit.PERCENT);
const transformWatt = createUnitTransform(0, EnergyUnit.W, NumberUnit.ONE);
const transformBatteryCareValueToTranslation = (value: number | [number, number]) => value
  ? I18n.t(T.analysis.dayChart.batteryCare.legendLabel.acitve)
  : I18n.t(T.analysis.dayChart.batteryCare.legendLabel.inactive);

export const chartTooltipStyles: ChartTooltipStyles = {
  /**
   * AREA CHART
   */
  [EnergyFlowSeriesKey.PRODUCTION_POWER]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.PRODUCTION_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWToKw,
  },
  [EnergyFlowSeriesKey.CONSUMPTION_POWER]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.CONSUMPTION_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWToKw,
  },
  [EnergyFlowSeriesKey.BATTERY_USOC]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.BATTERY_USOC],
    style: AnalysisTooltipSymbolKind.LINE,
    transformation: transformPercent,
  },
  [EnergyFlowSeriesKey.GRID_FEEDIN]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.GRID_FEEDIN],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWToKw,
  },
  [EnergyFlowSeriesKey.GRID_PURCHASE]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.GRID_PURCHASE],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWToKw,
  },
  [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.DIRECT_USAGE_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWToKw,
  },
  [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.PRODUCTION_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWToKw,
  },
  [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.CONSUMPTION_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWToKw,
  },
  batteryCare: {
    color: BATTERY_CARE_LINE_COLOR,
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformBatteryCareValueToTranslation,
  },

  /**
   * BAR CHART
   */
  [StatisticsSeriesKey.CONSUMED_ENERGY]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.CONSUMPTION_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWhToKwh,
  },
  [StatisticsSeriesKey.PRODUCED_ENERGY]: {
    color: AnalysisChartColors[EnergyFlowSeriesKey.PRODUCTION_POWER],
    style: AnalysisTooltipSymbolKind.ROUND,
    transformation: transformWhToKwh,
  },
};
