import { FeatureProvider } from '@sonnen/shared-web';

import { Env } from '+config/environment';

export enum FeatureName {
  ANALYSIS_FORECAST = 'AnalysisForecast',
  GUIDE_CONTACT_PAGE_IN_CP = 'GuideContactPageInCp',
  GUIDE_PAGES_IN_CP = 'GuidePhasesInCp',
  INACCURATE_MEASUREMENTS_BANNER = 'InaccurateMeasurementsBanner',
  LIVE_BUBBLES_AT_SUMMER_TIME_CHANGE = 'LiveBubblesAtSummerTimeChange',
  LIVE_STATE_EXPLANATION = 'LiveStateExplanation',
  PRODUCT_MIGRATION_CONTRACT_BONUS = 'ProductMigrationContractSwitchBonus',
  SYSTEM_NOTIFICATIONS = 'SystemNotifications',
  USAGE_ALLOWANCE_FROM_SALESFORCE = 'UsageAllowanceFromSalesforce',
  DOCUMENTS_INVOICES = 'DocumentsInvoices',
  DOCUMENTS_SYSTEM_DOCS = 'DocumentsSystemDocs',
  ANALYSIS_CSV_DOWNLOAD = 'AnalysisCsvDownload',
  HIDE_FIRST_ALLOWANCE_YEAR = 'HideFirstAllowanceYear',
}

FeatureProvider.createFeatureConfiguration({
  [FeatureName.ANALYSIS_FORECAST]: [Env.LOCAL, Env.DEVELOPMENT, Env.STAGING],
  [FeatureName.GUIDE_CONTACT_PAGE_IN_CP]: [Env.LOCAL, Env.DEVELOPMENT, Env.STAGING],
  [FeatureName.GUIDE_PAGES_IN_CP]: [Env.LOCAL, Env.DEVELOPMENT, Env.STAGING],
  [FeatureName.INACCURATE_MEASUREMENTS_BANNER]: [],
  [FeatureName.LIVE_BUBBLES_AT_SUMMER_TIME_CHANGE]: [Env.LOCAL, Env.DEVELOPMENT, Env.STAGING],
  [FeatureName.LIVE_STATE_EXPLANATION]: [Env.LOCAL, Env.DEVELOPMENT, Env.STAGING],
  [FeatureName.PRODUCT_MIGRATION_CONTRACT_BONUS]: [],
  [FeatureName.SYSTEM_NOTIFICATIONS]: [Env.LOCAL, Env.DEVELOPMENT, Env.STAGING],
  [FeatureName.USAGE_ALLOWANCE_FROM_SALESFORCE]: [Env.LOCAL, Env.DEVELOPMENT, Env.STAGING, Env.DEMO, Env.PRODUCTION],
  [FeatureName.DOCUMENTS_INVOICES]: [Env.LOCAL, Env.DEVELOPMENT, Env.STAGING, Env.DEMO, Env.PRODUCTION],
  [FeatureName.DOCUMENTS_SYSTEM_DOCS]: [Env.LOCAL, Env.DEVELOPMENT, Env.STAGING, Env.DEMO, Env.PRODUCTION],
  [FeatureName.ANALYSIS_CSV_DOWNLOAD]: [Env.LOCAL, Env.DEVELOPMENT, Env.STAGING, Env.DEMO, Env.PRODUCTION],
  [FeatureName.HIDE_FIRST_ALLOWANCE_YEAR]: [Env.LOCAL, Env.DEVELOPMENT, Env.STAGING, Env.DEMO, Env.PRODUCTION],
});
