import { ChartView, DataContainerTooltipExtension, TooltipEvent } from '@kanva/charts';
import { View } from '@kanva/core';
import { T } from '@sonnen/shared-i18n/customer';
import {
  AccessoriesChartSeries,
  AccessoriesSeriesKey,
  AnalysisChartColors,
  BarChartSeries,
  DAY_HOURS_COUNT,
  EnergyFlowSeriesKey,
  LegendDataSeries,
  LegendDataSeriesMarker,
  MAX_FUTURE_DAYS,
  Measurement,
  Point,
  StatisticsSeriesKey,
  TimeHelper,
  TimeUnit,
  useFeature,
  YEAR_MONTH_COUNT,
} from '@sonnen/shared-web';
import { compose, getOr, isEmpty, isNil, last, mapKeys, sum } from 'lodash/fp';
import * as moment from 'moment';
import { TouchEvent } from 'react';
import { I18n } from 'react-redux-i18n';

import { BATTERY_CARE_LINE_COLOR } from '+analysis/components/AnalysisAreaChart/AnalysisAreaChart.helper';
import { Resolution } from '+analysis/store/types/resolution.interface';
import { SelectedDate } from '+analysis/store/types/selectedDate.interface';
import { FieldName, StatisticsV2, StatisticsV2FilterResolution } from '+analysis/store/types/statisticsV2.interface';
import { SummerTimeChange, SummerTimeHelper } from '+app/shared/helpers/summerTime.helper';
import { MeasurementMethod } from '+app/shared/store/live/types/siteLiveData.interface';
import { BatteryStatusHistory } from '+battery/store/types';
import { FeatureName } from '+config/featureFlags';
import { AreaChartSeries } from './types/dataSeries.interface';
import { SiteForecastConsumption, SiteForecastData, SiteForecastProduction } from './types/forecast.interface';
import { SiteEvent } from './types/siteEvents.interface';
import { SiteMeasurements } from './types/siteMeasurements.interface';

export type MeasurementsFilters = {
  start: string,
  end: string,
};

export const visibleStatisticsSeriesKeys = [
  StatisticsSeriesKey.PRODUCED_ENERGY,
  StatisticsSeriesKey.CONSUMED_ENERGY,
];

export const meterGridMeasurementMethods = [
  MeasurementMethod.METER_GRID,
  MeasurementMethod.METER_GRID_PV,
  MeasurementMethod.METER_GRID_PV_FEED_IN,
];

export enum EventType {
  BACKUP_ACTIVE = 'backup_active',
  BATTERY_INVERTER_STATUS = 'battery_inverter_status',
}

export const getMeasurementsStartDate =
  TimeHelper.getStartOfDate();

export const getMeasurementsEndDate = compose(
  TimeHelper.getNextDate,
  TimeHelper.getStartOfDate(),
);

export const getMeasurementsDefaultDateRange = () => ({
  start: moment().startOf('day').format(),
  end: moment().endOf('day').format(),
});

export const getResolutionForPeriod = (period: TimeUnit) => ({
  [TimeUnit.DAY]: Resolution.RES_1_HOUR,
  [TimeUnit.MONTH]: Resolution.RES_1_DAY,
  [TimeUnit.YEAR]: Resolution.RES_1_MONTH,
})[period];

export const parseMeasurementResolution = (value: string) => {
  const [, numericValue, unitValue] = value.match(/^(\d+)\-?(.)$/) || [undefined, '0', undefined];
  const num = parseInt(numericValue || '0', 10);

  switch (unitValue) {
    case 'h':
    case 'hour':
      return num * 60;
    case 'M':
    case 'month':
      return num * 60 * 24 * 30; // @TODO: maybe factor in month length
    case 'Y':
    case 'year':
      return num * 60 * 24 * 365; // @TODO: maybe factor in leap years
    case 'm':
    case 'minute':
    default:
      return num;
  }
};

export const factorizeTimestampForResolution = (unixDate: number, res: number) =>
  (i: number) => unixDate + (60 * res * i);

export const fillMeasurementDataGaps = (measurement: Measurement) =>
  !isNil(measurement)
    ? measurement.reduce((prev, curr, i) => {
      if (i === 0) {
        prev!.push(curr);
      } else if (isNil(curr)) {
        prev!.push(last(prev!)!);
      } else {
        prev!.push(curr);
      }

      return prev;
    }, [] as Measurement)
    : measurement;

export const filterMeasurementsByResolution = (
  resolution: number,
) => (measurement: Measurement) => !isNil(measurement)
  ? measurement.filter((_, index, arr) =>
    (index % resolution === 0)
    || (index === arr.length - 1),
  )
  : [];

export const summerTimeAdjustments = (summerTimeChange: SummerTimeChange | undefined) =>
  (measurement: Measurement) => {
    if (isNil(measurement)) {
      return [];
    } else if (!summerTimeChange) {
      return measurement;
    }

    const { isSummerTimeStartDate, isSummerTimeEndDate } = summerTimeChange;
    const oneHour = 60;
    const winterToSummerTimeGap = new Array(oneHour).fill(null);

    switch (true) {
      case isSummerTimeStartDate && measurement.length < oneHour * 24:
        return [
          ...measurement.slice(0, oneHour * 2),
          ...winterToSummerTimeGap,
          ...measurement.slice(oneHour * 2, measurement.length - 1),
        ];
      case isSummerTimeEndDate && measurement.length > oneHour * 24:
        return [
          ...measurement.slice(0, oneHour * 2),
          ...measurement.slice(oneHour * 3, measurement.length - 1),
        ];
      default:
        return measurement;
    }
  };

export const normalizeMeasurementsToTime = (
  resolution: number,
  startDate: moment.Moment,
) => (measurements: Measurement): Point[] => {
  const unixStartDate = startDate.unix();
  const factorizeTimestamp = factorizeTimestampForResolution(unixStartDate, resolution);
  return !isNil(measurements) ? measurements.map((measurement, i) => ({
    x: factorizeTimestamp(i),
    y: normalizeMeasurementValue(measurement),
  })) : [];
};

export const normalizeMeasurementValue = (y: number | null) => {
  if (!y) {
    return null;
  }

  return y > 0 ? y : null;
};

const getTrimmedMeasurements = (measurements: Measurement, periodLength: number) => {
  return measurements
    ? measurements.length > periodLength
      ? measurements.slice(0, periodLength)
      : measurements
    : [];
};

export const factorizeBarChartSeries = (
  measurements: Measurement,
  statisticsSelectedDate: SelectedDate,
) => {
  const { period, date } = statisticsSelectedDate;

  if (!!measurements) {
    switch (period) {
      case TimeUnit.DAY:
        const dayMeasurements = getTrimmedMeasurements(measurements, DAY_HOURS_COUNT);

        return dayMeasurements
          .concat(Array(DAY_HOURS_COUNT - dayMeasurements.length).fill(null))
          .map((y, x) => ({ x, y }));
      case TimeUnit.MONTH:
        const daysInMounthCount = moment(date).daysInMonth();
        const monthMeasurements = getTrimmedMeasurements(measurements, daysInMounthCount);

        return monthMeasurements
          .concat(Array(daysInMounthCount - monthMeasurements.length).fill(null))
          .map((y, x) => ({ x, y }));
      case TimeUnit.YEAR:
        const yearMeasurements = getTrimmedMeasurements(measurements, YEAR_MONTH_COUNT);

        return yearMeasurements
          .concat(Array(YEAR_MONTH_COUNT - yearMeasurements.length).fill(null))
          .map((y, x) => ({ x, y }));
      default:
        return measurements.map((y, x) => ({ x, y }));
    }
  }

  return [];
};

export const getAvailableFilters = (measurementMethod: MeasurementMethod) => {
  // TODO: modify colors so that they are unique for each parameter
  const filters = {
    production: {
      key: EnergyFlowSeriesKey.PRODUCTION_POWER,
      color: AnalysisChartColors[EnergyFlowSeriesKey.PRODUCTION_POWER],
      name: I18n.t(T.history.chart.labels.production),
    },
    consumption: {
      key: EnergyFlowSeriesKey.CONSUMPTION_POWER,
      color: AnalysisChartColors[EnergyFlowSeriesKey.CONSUMPTION_POWER],
      name: I18n.t(T.history.chart.labels.consumption),
    },
    batteryUsoc: {
      key: EnergyFlowSeriesKey.BATTERY_USOC,
      color: AnalysisChartColors[EnergyFlowSeriesKey.BATTERY_USOC],
      name: I18n.t(T.history.chart.labels.battery_usoc),
      markerType: LegendDataSeriesMarker.LINE,
    },
    gridFeedin: {
      key: EnergyFlowSeriesKey.GRID_FEEDIN,
      color: AnalysisChartColors[EnergyFlowSeriesKey.GRID_FEEDIN],
      name: I18n.t(T.history.chart.labels.grid_feedin),
    },
    gridPurchase: {
      key: EnergyFlowSeriesKey.GRID_PURCHASE,
      color: AnalysisChartColors[EnergyFlowSeriesKey.GRID_PURCHASE],
      name: I18n.t(T.history.chart.labels.grid_purchase),
    },
    directUsage: {
      key: EnergyFlowSeriesKey.DIRECT_USAGE_POWER,
      color: AnalysisChartColors[EnergyFlowSeriesKey.DIRECT_USAGE_POWER],
      name: I18n.t(T.history.chart.labels.direct_usage_power),
    },
    productionForecast: {
      key: EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER,
      color: AnalysisChartColors[EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER],
      name: I18n.t(T.forecast.chart.labels.production),
    },
    consumptionForecast: {
      key: EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER,
      color: AnalysisChartColors[EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER],
      name: I18n.t(T.forecast.chart.labels.consumption),
    },
    batteryCare: {
      key: 'batteryCare' as EnergyFlowSeriesKey,
      color: BATTERY_CARE_LINE_COLOR,
      name: I18n.t(T.yourDay.chart.labels.batteryCare),
    },
  };

  switch (measurementMethod) {
    case MeasurementMethod.METER_GRID:
      return [filters.gridFeedin, filters.gridPurchase];
    case MeasurementMethod.METER_GRID_PV:
      return [filters.production, filters.consumption, filters.directUsage];
    case MeasurementMethod.METER_GRID_PV_FEED_IN:
      return [filters.production, filters.consumption];
    default:
      return [filters.production, filters.consumption, filters.directUsage, filters.batteryUsoc, filters.batteryCare];
  }
};

export const getLegendDataSeries = (
  activeDataSeries: EnergyFlowSeriesKey[],
  measurementMethod: MeasurementMethod,
): LegendDataSeries[] => getAvailableFilters(measurementMethod)
  .map(serie => ({
    ...serie,
    active: activeDataSeries.includes(serie.key),
  }));

export const hasMeasurement = (
  attributes: SiteMeasurements,
  attributeKey: EnergyFlowSeriesKey | StatisticsSeriesKey | AccessoriesSeriesKey,
) => Boolean(
  !isEmpty(attributes[attributeKey])
  && attributes[attributeKey].some((point: Measurement) => !isNil(point)),
);

export const hasMeasurements = (measurements: SiteMeasurements | undefined) => Boolean(
  !isNil(measurements)
  && !isNil(measurements.measurementMethod)
  && (hasMeasurement(measurements, EnergyFlowSeriesKey.PRODUCTION_POWER)
    || hasMeasurement(measurements, EnergyFlowSeriesKey.CONSUMPTION_POWER)
    || hasMeasurement(measurements, EnergyFlowSeriesKey.BATTERY_USOC)
    || hasMeasurement(measurements, EnergyFlowSeriesKey.DIRECT_USAGE_POWER)
    || hasMeasurement(measurements, EnergyFlowSeriesKey.GRID_FEEDIN)
    || hasMeasurement(measurements, EnergyFlowSeriesKey.GRID_PURCHASE))
  && measurements.measurementMethod !== 'meter-error',
);

export const hasHeaterPowerMeasurements = (measurements: SiteMeasurements | undefined) => Boolean(
  !isNil(measurements)
  && hasMeasurement(measurements, AccessoriesSeriesKey.HEATER_POWER)
  && measurements.measurementMethod !== 'meter-error',
);

const hasStatisticsFields = (statistics: StatisticsV2) => [
  FieldName.PRODUCED,
  FieldName.CONSUMED,
  FieldName.GRID_PURCHASE,
  FieldName.GRID_FEEDIN,
].every(fieldName => statistics.fields.find(field => field.name === fieldName));

const hasValues = ({ values }: StatisticsV2) => values && !isEmpty(values);

export const hasStatistics = (statistics: StatisticsV2 | undefined): statistics is StatisticsV2 => !!statistics
  && hasStatisticsFields(statistics)
  && hasValues(statistics);

export const hasSeries = (series: AreaChartSeries | BarChartSeries | AccessoriesChartSeries) => (
  !!(series && Object.keys(series).find(serieKey => {
    const serie = series[serieKey];

    return !isEmpty(serie);
  }))
);

export const isPeriodDay = (period: TimeUnit) =>
  period === TimeUnit.DAY;

export const aggregateMeasurement = (measurement: Measurement) =>
  measurement && measurement.length
    ? sum(measurement.filter((m: number | null): m is number => !isNil(m)))
    : 0;

export const createTransform = (
  date: moment.Moment,
  measurements: SiteMeasurements,
  summerTimeChange: SummerTimeChange | undefined,
  isEaton?: boolean,
) => {
  const resolution = parseMeasurementResolution(isEaton
    ? '3m'
    : measurements.resolution || '1m',
  );

  const normalize = compose(
    normalizeMeasurementsToTime(resolution, date),
    summerTimeAdjustments(summerTimeChange),
    filterMeasurementsByResolution(resolution),
  );

  /**
   * @note Eaton systems
   * Batteries of controller_type {eaton} have limited storage.
   * -----------------------------------------------------------
   * These batteries can only store full measurement resolution of up to two days. That means
   * for other days these batteries do an aggregation which is very unpredictable and returns
   * variable amount of data and data gaps in a cycle of 1440 minutes, so we're just filling
   * them up with data.
   */
  return isEaton && !TimeHelper.isToday(date) && !TimeHelper.isYesterday(date)
    ? compose(normalize, fillMeasurementDataGaps)
    : normalize;
};

export const mapBatteryCareToAreaChartSeries = (
  batteryCareHistory: BatteryStatusHistory[],
  selectedDate: moment.Moment,
): AreaChartSeries['batteryCare'] => {
  let currentMinute = 0;
  const startOfDay = moment(selectedDate).startOf('day');
  const isToday = selectedDate.isSame(moment(Date.now(), 'day'));
  const minutesInSelectedDay = isToday
    ? moment(Date.now()).diff(startOfDay, 'minutes')
    : 1440;

  if (batteryCareHistory.length === 0) {
    return [];
  }

  const batteryCareSerie = [];
  const startOfDayTimestamp = startOfDay.valueOf() / 1000;

  while (currentMinute < minutesInSelectedDay) {
    const batteryCarePoint = batteryCareHistory[0];
    const currentMinuteTimestampStart = startOfDayTimestamp + (currentMinute * 60);
    const currentMinuteTimestampEnd = currentMinuteTimestampStart + 60;

    const emptyPoint = {
      x: currentMinuteTimestampEnd,
      y: null,
    };

    if (!batteryCarePoint) {
      batteryCareSerie.push(emptyPoint);

      currentMinute++;
      continue;
    }

    const batteryCarePointTimestamp = moment(batteryCarePoint.timestamp).valueOf() / 1000;

    if (
      batteryCarePointTimestamp > currentMinuteTimestampStart &&
      batteryCarePointTimestamp <= currentMinuteTimestampEnd
    ) {
      batteryCareHistory.shift();

      if (batteryCarePoint.value.includes('maintenance')) {
        batteryCareSerie.push({
          x: currentMinuteTimestampEnd,
          y: 1,
        });
      } else {
        batteryCareSerie.push(emptyPoint);
      }

    } else {
      batteryCareSerie.push(emptyPoint);
    }

    currentMinute++;
  }

  return batteryCareSerie;
};

// NOTE: check here
const getFirstForecastSeriesPoint = (date: moment.Moment, batteryTimezone: string) =>
  ({ x: moment.tz(date, batteryTimezone).startOf('day').unix(), y: null });

export const updateForecastSeries = (
  { forecastSeries, liveDataTimestamp, liveDataPower, batteryTimezone }:
  { forecastSeries: Point[], liveDataTimestamp: number, liveDataPower: number | undefined, batteryTimezone: string },
) => {
  if (!forecastSeries.length) { return []; }

  const isLastDataToday = moment.unix(liveDataTimestamp).tz(batteryTimezone).isSame(moment.tz(batteryTimezone), 'day');
  const liveSeriesPoint = getLiveSeriesPoint(
    isLastDataToday
      ? liveDataTimestamp
      : moment.tz(batteryTimezone).startOf('day').unix(),
    liveDataPower || 0, // TODO handle using 0 if measurements data is delayed
  );

  return forecastSeries.length
    ? [
        getFirstForecastSeriesPoint(moment(), batteryTimezone),
        liveSeriesPoint,
        ...forecastSeries.filter(forecast => moment.unix(forecast.x).isSameOrAfter(moment.unix(liveSeriesPoint.x))),
      ]
    : [];
};

const filterCurrentDayForecast = (
  forecasts: SiteForecastData[],
  date: moment.Moment,
  batteryTimezone: string,
) => {
  // TODO handle other filtering if battery is offline (missing current data) SON-8975
  const isToday = date.tz(batteryTimezone).isSame(moment.tz(batteryTimezone), 'day');

  if (isToday) {
    return forecasts.filter(forecast => moment(forecast.forecastDatetime).isBetween(
      moment(),
      moment.tz(date, batteryTimezone).endOf('day'),
      // @NOTE ^: cloning is needed because `.endOf()` mutates the date obj.
    ));
  }

  return forecasts.filter(forecast => moment.tz(forecast.forecastDatetime, batteryTimezone)
    .isSame(date.tz(batteryTimezone), 'day'));
};

export const transformForecastData = (
  { date, lastDataPointTimestamp, lastDataPointPower, forecasts, forecastDataKey, batteryTimezone }:
  {
    date: moment.Moment,
    lastDataPointTimestamp: number,
    lastDataPointPower: number,
    forecasts: SiteForecastProduction[] | SiteForecastConsumption[],
    forecastDataKey: string,
    batteryTimezone: string,
  },
): Point[] => {
  if (!forecasts.length) {
    return [];
  }
  const isTodayOrFuture = date.tz(batteryTimezone).isSameOrAfter(moment.tz(batteryTimezone), 'day');
  const isToday = date.tz(batteryTimezone).isSame(moment.tz(batteryTimezone), 'day');
  const currentDayForecast = filterCurrentDayForecast(forecasts, date, batteryTimezone);

  return isTodayOrFuture
    ? [
        getFirstForecastSeriesPoint(date, batteryTimezone),
        ...isToday ? [{ x: lastDataPointTimestamp, y: lastDataPointPower }] : [],
        ...currentDayForecast.map((forecast) => ({
          x: moment(forecast.forecastDatetime).unix(),
          y: forecast[forecastDataKey],
        })),
      ]
    : [];
};

export const prepareBackupBoxSeries = (
  // Note: unixEndTime passed as events contain only
  // activity change points
  { events, date }:
  { events: SiteEvent[], date: Date },
) => {
  const unixStartDate = TimeHelper.getUnixFromDate(date);
  const backupBoxSeries: Point[] = events
    .filter(e => e.eventType === EventType.BACKUP_ACTIVE && e.value && e.value.length >= 2)
    .reduce((result, event) => {
      const timestamp = moment(event.timestamp).unix();
      const values = event.value.map(v => v === 'true' ? 1 : 0);
      result.push(
        { x: timestamp - 1, y: values[0] },
        { x: timestamp, y: values[1] },
      );
      return result;
       }, [] as Point[]);

  const initialPoint = {
      x: unixStartDate,
      y: backupBoxSeries.length
        ? backupBoxSeries[0].y === 1 ? 0 : 1
        : 0,
    };

  backupBoxSeries.unshift(initialPoint);

  backupBoxSeries.push({
    x: TimeHelper.isToday(date)
      ? TimeHelper.getUnixFromDate(new Date())
      : TimeHelper.getUnixFromDate(TimeHelper.getEndOfDate()(date)),
    y: backupBoxSeries[backupBoxSeries.length - 1].y,
  });

  return backupBoxSeries;
};

export const isMKMeterUser = (measurementMethod: string) =>
  meterGridMeasurementMethods.some(method => method === measurementMethod);

export const getFactorizedDataSeriesKeys = (
  previousDataSeriesKeys: EnergyFlowSeriesKey[],
  previousMeasurementMethod: MeasurementMethod | undefined,
  currentMeasurementMethod: MeasurementMethod | undefined,
) => {
  const defaultDataSeriesKeys: EnergyFlowSeriesKey[] = [
    EnergyFlowSeriesKey.PRODUCTION_POWER,
    EnergyFlowSeriesKey.CONSUMPTION_POWER,
    EnergyFlowSeriesKey.DIRECT_USAGE_POWER,
    EnergyFlowSeriesKey.BATTERY_USOC,
    EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER,
    EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER,
    'batteryCare' as EnergyFlowSeriesKey,
  ];

  if (previousMeasurementMethod === currentMeasurementMethod) {
    return previousDataSeriesKeys;
  }

  switch (currentMeasurementMethod) {
    case MeasurementMethod.BATTERY:
      return defaultDataSeriesKeys;
    case MeasurementMethod.METER_GRID:
      return [
        EnergyFlowSeriesKey.GRID_PURCHASE,
        EnergyFlowSeriesKey.GRID_FEEDIN,
      ];
    case MeasurementMethod.METER_GRID_PV:
      return [
        EnergyFlowSeriesKey.PRODUCTION_POWER,
        EnergyFlowSeriesKey.CONSUMPTION_POWER,
        EnergyFlowSeriesKey.DIRECT_USAGE_POWER,
        EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER,
        EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER,
      ];
    case MeasurementMethod.METER_GRID_PV_FEED_IN:
      return [
        EnergyFlowSeriesKey.PRODUCTION_POWER,
        EnergyFlowSeriesKey.CONSUMPTION_POWER,
        EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER,
        EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER,
      ];
    default:
      return defaultDataSeriesKeys;
  }
};

export const handleTooltipTouchEvent = (
  e: TouchEvent,
  view: View<any> | undefined,
  tooltipExtension: DataContainerTooltipExtension | undefined,
  tooltipEvent: TooltipEvent | undefined,
) => {
  if (tooltipEvent && tooltipExtension && view && tooltipExtension) {
    const x = e.targetTouches[0].pageX - tooltipEvent.pointerEvent.offset.left;
    tooltipExtension.simulateAbsoluteCanvasPosition(view as ChartView<any, any>, { x, y: 0 });
  }
};

export const getLiveSeriesPoint = (unixDate: number, value: number): Point => {
  return {
    x: unixDate,
    y: value >= 0 ? value : null,
  };
};

export const areLiveBubblesDisabled = (
  areaChartSeries: AreaChartSeries,
  summerTimeChange: SummerTimeChange | undefined,
) =>
  Boolean(!hasSeries(areaChartSeries) ||
  (useFeature(FeatureName.LIVE_BUBBLES_AT_SUMMER_TIME_CHANGE).isDisabled &&
  summerTimeChange && SummerTimeHelper.isSummerTimeChangeDay(summerTimeChange)));

export const getDirectUsagePower = (production: number, consumption: number) => {
  return Math.min(production, consumption);
};

export const getMatchingCacheData = (
  cacheData: StatisticsV2[],
  date: Date,
  resolution: StatisticsV2FilterResolution,
) => cacheData.find(
  cacheElement =>
    new Date(cacheElement.startAt).toString() === getMeasurementsStartDate(date).toString() &&
    resolution === cacheElement.resolution,
);

export const getLastDataPoint = (dataPoints: Point[]) =>
  dataPoints.length ? dataPoints.slice(-1)[0] : { x: moment().unix(), y: 0 };

export const getLastForecastDate = (forecast: SiteForecastData[]): string | undefined => !isEmpty(forecast)
  ? forecast[forecast.length - 1].forecastDatetime
  : undefined;

export const calculateMaxFutureDate = (forecasts: SiteForecastData[]): number => {
  const lastForecastDate = moment(getLastForecastDate(forecasts)).valueOf(); // moment(undefined) returns current date.
  const maxDate = moment().add(MAX_FUTURE_DAYS, 'days').valueOf();

  return Math.min(lastForecastDate, maxDate);
};

export const getMaxForecastsDate = (
  production: SiteForecastData[] | undefined,
  consumption: SiteForecastData[] | undefined,
): Date => {
  switch (true) {
    case isEmpty(consumption) && isEmpty(production):
      return new Date();
    case isEmpty(production):
      return new Date(calculateMaxFutureDate(consumption!));
    case isEmpty(consumption):
      return new Date(calculateMaxFutureDate(production!));
    default:
      return new Date(Math.max(calculateMaxFutureDate(consumption!), calculateMaxFutureDate(production!)));
  }
};

export interface DataSeries {
  consumption: Measurement<number>;
  production: Measurement<number>;
  gridPurchase: Measurement<number>;
  gridFeedin: Measurement<number>;
}

export const mapStatisticsToDataSeries = (statistics: StatisticsV2 | undefined): DataSeries => {
  // NOTE ensures all required fields are available
  if (!hasStatistics(statistics)) {
    throw new Error();
  }

  const consumptionIndex = statistics.fields.find(field => field.name === FieldName.CONSUMED)!.index;
  const productionIndex = statistics.fields.find(field => field.name === FieldName.PRODUCED)!.index;
  const gridPurchaseIndex = statistics.fields.find(field => field.name === FieldName.GRID_PURCHASE)!.index;
  const gridFeedinIndex = statistics.fields.find(field => field.name === FieldName.GRID_FEEDIN)!.index;

  const consumption: Measurement<number> = [];
  const production: Measurement<number> = [];
  const gridPurchase: Measurement<number> = [];
  const gridFeedin: Measurement<number> = [];

  switch (statistics.resolution) {
    case Resolution.RES_1_MONTH:
      const valuesByMonth = mapKeys(timestamp => new Date(timestamp).getMonth(), statistics.values);
      for (let i = 0; i < 12; i++) {
        const currentMonth = valuesByMonth[i] || null;
        consumption.push(getOr(0, consumptionIndex, currentMonth));
        production.push(getOr(0, productionIndex, currentMonth));
        gridPurchase.push(getOr(0, gridPurchaseIndex, currentMonth));
        gridFeedin.push(getOr(0, gridFeedinIndex, currentMonth));
      }
      break;
    case Resolution.RES_1_DAY:
      const daysInMonth = moment(statistics.startAt).daysInMonth();
      const valuesByDay = mapKeys(timestamp => new Date(timestamp).getDate(), statistics.values);
      for (let i = 1; i < daysInMonth + 1; i++) {
        const currentDay = valuesByDay[i] || null;
        consumption.push(getOr(0, consumptionIndex, currentDay));
        production.push(getOr(0, productionIndex, currentDay));
        gridPurchase.push(getOr(0, gridPurchaseIndex, currentDay));
        gridFeedin.push(getOr(0, gridFeedinIndex, currentDay));
      }
      break;
    default:
      const valuesByHour = mapKeys(timestamp => new Date(timestamp).getHours(), statistics.values);
      for (let i = 0; i < 24; i++) {
        const currentHour = valuesByHour[i] || null;
        consumption.push(getOr(0, consumptionIndex, currentHour));
        production.push(getOr(0, productionIndex, currentHour));
        gridPurchase.push(getOr(0, gridPurchaseIndex, currentHour));
        gridFeedin.push(getOr(0, gridFeedinIndex, currentHour));
      }
  }

  return { consumption, production, gridPurchase, gridFeedin };
};
