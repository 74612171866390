import { DocumentStatus } from '@sonnen/shared-web';

import { ResponseError } from '+shared/network/network.interface';
import { Query } from '+shared/store/query/query.state';
import { CessionStatus, CessionTimestamps, Document, ImpactAnalysis, Lead, LeadCollection, Offer } from './types';
import { Partner } from './types/partner.interface';

import { GuideAcceptanceState, initialState as initialAcceptanceState } from '+guide/+acceptance/store/+acceptance.state';
import { GuideDiscoveryState, initialState as initialDiscoveryState } from '+guide/+discovery/store/+discovery.state';
import { GuideOverviewState, initialState as initialOverviewState } from '+guide/+overview/store/+overview.state';
import { GuideSetupState, initialState as initialSetupState } from '+guide/+setup/store/+setup.state';

/**
 * COMMON GUIDE AUTH STATE
 */

export interface GuideAuthState {
  token?: string;
  verifiedToken?: string;
  persistedTokens: Record<string, string>;
  verifyingIdentity: boolean;
  error?: ResponseError;
}

export const initialAuthState: GuideAuthState = {
  token: undefined,
  verifiedToken: undefined,
  persistedTokens: {},
  verifyingIdentity: false,
  error: undefined,
};

/**
 * COMMON GUIDE STATE
 */

export const GUIDE_SYNC_QUERY = 'guideSyncQuery';
export const GET_LEAD_QUERY = 'getLeadQuery';
export const GET_LEAD_COLLECTION_QUERY = 'getLeadCollectionQuery';
export const GET_PARTNER_QUERY = 'getPartnerQuery';
export const GET_OFFERS_QUERY = 'getOffersQuery';
export const GET_HARDWARE_OFFER_QUERY = 'getHardwareOfferQuery';
export const GET_FLAT_OFFER_QUERY = 'getFlatOfferQuery';
export const GET_FLAT_OFFER_FILE_QUERY = 'getFlatOfferFileQuery';
export const GET_HARDWARE_OFFER_FILE_QUERY = 'getHardwareOfferQuery';
export const GET_IMPACT_ANALYSIS_LIST_QUERY = 'getImpactAnalysisListQuery';
export const GET_IMPACT_ANALYSIS_FILE_QUERY = 'getImpactAnalysisFileQuery';
export const POST_OFFER_DOCUMENT_QUERY = 'postOfferDocumentQuery';
export const GET_ORDER_CONFIRMATION_FILE_QUERY = 'getOrderConfirmationFileQuery';
export const GET_CESSION_DOCUMENT_FILE_QUERY = 'getCessionDocumentFileQuery';
export const GET_CESSION_DOCUMENT = 'getCessionDocument';
export const GET_CESSION_STATUS = 'getCessionStatus';

export interface GuideCommonState {
  lead?: Lead;
  partner?: Partner;
  documentUrl?: string;
  offers: Offer[];
  hardwareOffers: Record<string, Document[]>;
  impactAnalysisList: ImpactAnalysis[];
  orderConfirmationStatus: DocumentStatus | undefined;
  cessionDocumentStatus: DocumentStatus | undefined;
  phaseActiveTab: number;
  cessionDocUrl?: string;
  cessionStatus?: CessionStatus;
  cessionTimestamps: CessionTimestamps;
  cessionFailed: boolean;
  [GUIDE_SYNC_QUERY]: Query;
  [GET_LEAD_QUERY]: Query<Lead>;
  [GET_LEAD_COLLECTION_QUERY]: Query<LeadCollection>;
  [GET_OFFERS_QUERY]: Query<Offer>;
  [GET_PARTNER_QUERY]: Query<Partner>;
  [GET_HARDWARE_OFFER_FILE_QUERY]: Query<string>;
  [GET_FLAT_OFFER_FILE_QUERY]: Query<string>;
  [GET_IMPACT_ANALYSIS_LIST_QUERY]: Query<ImpactAnalysis>;
  [POST_OFFER_DOCUMENT_QUERY]: Query;
  [GET_ORDER_CONFIRMATION_FILE_QUERY]: Query<string>;
  [GET_CESSION_DOCUMENT_FILE_QUERY]: Query<string>;
  [GET_CESSION_DOCUMENT]: Query<Lead>;
  [GET_CESSION_STATUS]: Query<Lead>;
}

export const initialCommonState: GuideCommonState = {
  lead: undefined,
  partner: undefined,
  documentUrl: undefined,
  offers: [],
  hardwareOffers: {},
  impactAnalysisList: [],
  orderConfirmationStatus: undefined,
  cessionDocumentStatus: undefined,
  phaseActiveTab: 0,
  cessionDocUrl: undefined,
  cessionStatus: undefined,
  cessionTimestamps: {},
  cessionFailed: false,
  [GUIDE_SYNC_QUERY]: {},
  [GET_LEAD_QUERY]: {},
  [GET_LEAD_COLLECTION_QUERY]: {},
  [GET_OFFERS_QUERY]: {},
  [GET_PARTNER_QUERY]: {},
  [GET_HARDWARE_OFFER_FILE_QUERY]: {},
  [GET_FLAT_OFFER_FILE_QUERY]: {},
  [GET_IMPACT_ANALYSIS_LIST_QUERY]: {},
  [POST_OFFER_DOCUMENT_QUERY]: {},
  [GET_ORDER_CONFIRMATION_FILE_QUERY]: {},
  [GET_CESSION_DOCUMENT_FILE_QUERY]: {},
  [GET_CESSION_DOCUMENT]: {},
  [GET_CESSION_STATUS]: {},
};

/**
 * ROOT GUIDE STATE
 */

export interface GuideState {
  auth: GuideAuthState;
  common: GuideCommonState;
  acceptance: GuideAcceptanceState;
  overview: GuideOverviewState;
  setup: GuideSetupState;
  discovery: GuideDiscoveryState;
}

export const initialState: GuideState = {
  auth: initialAuthState,
  common: initialCommonState,
  acceptance: initialAcceptanceState,
  overview: initialOverviewState,
  setup: initialSetupState,
  discovery: initialDiscoveryState,
};
