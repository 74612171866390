import { Header as SharedHeader, Logo } from '@sonnen/shared-web';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { getPath } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';

interface Props {
  redirectTo: RouteName;
  logo?: React.ReactNode;
}

export const Header: React.FC<Props> = ({
  redirectTo,
  logo,
  children,
}) => (
  <SharedHeader
    theme={'dark'}
    logo={
      <Link to={getPath(redirectTo)}>
        {logo || <Logo />}
      </Link>
    }
  >
    {children}
  </SharedHeader>
);
