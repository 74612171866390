import { getOfferFlatProduct } from '+app/+guide/store/+guide.helper';
import { Document, OfferProductStatus, OfferProductType, Proposal } from '+app/+guide/store/types';
import { GuidePurchaseProposalContent, GuidePurchaseProposalFooter } from '+guide/+purchase/components';
import * as React from 'react';

// @TODO remove T.customerGuidance.purchase.step.acceptOffer.offer.title from shared-i18n

export const GuidePurchaseProposal: React.FC<{
  proposal: Proposal,
  hardwareOffers?: Document[],
  onCheckOffer?: () => void;
  index: number,
  handleToggleTermsModal: () => void;
  toggleDetails?: () => void;
  isExpanded?: boolean;
}> = ({
  proposal,
  onCheckOffer,
  handleToggleTermsModal,
  hardwareOffers,
  toggleDetails,
  isExpanded,
}) => (
  <div>
    <GuidePurchaseProposalContent
      proposal={proposal}
      hardwareOffers={hardwareOffers}
      handleToggleTermsModal={handleToggleTermsModal}
      toggleDetails={toggleDetails}
      isExpanded={isExpanded}
    />
    {!(proposal.offer && getOfferFlatProduct(proposal.offer)!.status === OfferProductStatus.ACCEPTED) && (
        <GuidePurchaseProposalFooter
          onCheckOffer={onCheckOffer}
        />
      )}
  </div>
);
