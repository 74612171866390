import { T } from '@sonnen/shared-i18n/customer';
import { DocumentStatus, ModalDocumentDownload } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { isSpecificFlatProductInOffer } from '+app/+guide/+acceptance/store/+acceptance.helper';
import {
  getPrimaryFlatConfigurationDocumentKey,
  getPrimaryFlatConfigurationDocumentStatus,
  getPrimaryImpactAnalysisDocumentStatus,
} from '+app/+guide/+purchase/store/+offer.selectors';
import { GuideDocumentErrorModal, GuideTextWithTooltip, GuideVatInfo } from '+app/+guide/components';
import { GuideActions } from '+app/+guide/store/+guide.actions';
import {
  getDocumentUrl,
  getImpactAnalysisList,
} from '+app/+guide/store/+guide.selectors';
import { getGuideLead } from '+app/+guide/store/+lead.selectors';
import { DownloadLink, KeyValueRow, Portal } from '+app/shared/components';
import { PortalExit } from '+app/shared/helpers';
import { StoreState } from '+app/store/store.interface';
import {
  GuidePurchaseAccordion,
  GuidePurchaseFlatOfferSection,
  GuidePurchaseProduct,
} from '+guide/+purchase/components';
import { FlatDocumentType, OfferProductType, Proposal } from '+guide/store/types';
import { getFormattedDate } from '+legacy/helpers/dates';
import { ConfigurationHelper } from '+shared/helpers/configuration.helper';
import { mapActions } from '+utils/redux';
import {
  factorizeConfBasicInfo,
  factorizeExpectedCashbackInfo,
  factorizeMonthlyPrepaymentInfo,
  factorizeVppBonusInfo,
} from './GuidePurchaseFlatOffer.helper';

import './GuidePurchaseFlatOffer.component.scss';

const mapStateToProps = (state: StoreState, { proposal: { offer, ia } }: ComponentProps) => ({
  lead: getGuideLead(state),
  documentKey: offer && getPrimaryFlatConfigurationDocumentKey(state)({ offerId: offer.id }),
  documentUrl: getDocumentUrl(state),
  documentStatusConfiguration: offer && getPrimaryFlatConfigurationDocumentStatus(state)({ offerId: offer.id }),
  documentStatusImpactAnalysis: ia && getPrimaryImpactAnalysisDocumentStatus(state)({ impactAnalysisId: ia.id }),
  impactAnalysisList: getImpactAnalysisList(state),
});

const mapDispatchToProps = mapActions({
  startDocumentPolling: GuideActions.startDocumentPolling,
  stopDocumentOfferPolling: GuideActions.stopDocumentPolling,
  regenerateDocument: GuideActions.regenerateDocument,
});

interface ComponentProps {
  handleToggleTermsModal: () => void;
  toggleDetails?: () => void;
  isExpanded?: boolean;
  proposal: Proposal;
}

export type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & ComponentProps
  ;

const createConfigurationBasicDetails = (proposal: Proposal) => (
  <>
    {factorizeConfBasicInfo(proposal).map(({ title, value }, index) =>
      <KeyValueRow
        title={title}
        value={value}
        key={`offer-line-${index}`}
      />,
    )}
  </>
);

const createConfigurationDescription = (proposal: Proposal) => (
  <>
    {factorizeMonthlyPrepaymentInfo(proposal).map(({ title, value, type }, index) =>
      <KeyValueRow
        title={title}
        value={value}
        type={type}
        key={`offer-line-${index}`}
      />,
    )}
    <div className={'c-guide-purchase-flat-offer__product-divider'} />
    {factorizeExpectedCashbackInfo(proposal).map(({ title, value, type }, index) =>
      <KeyValueRow
        title={title}
        value={value}
        type={type}
        key={`offer-line-${index}`}
      />,
    )}
    {Boolean(factorizeVppBonusInfo(proposal).length) && (
      <div className={'c-guide-purchase-flat-offer__product-divider'} />
    )}
    {factorizeVppBonusInfo(proposal).map(({ title, value, type }, index) =>
      <KeyValueRow
        title={title}
        value={value}
        type={type}
        key={`offer-line-${index}`}
      />,
    )}
    <div className={'c-guide-purchase-flat-offer__product-divider'} />
    <GuideVatInfo
      rate={ConfigurationHelper.formatTaxRate(proposal.valueAddedTax)}
    />
  </>
);

export const GuidePurchaseFlatOfferComponent: React.FC<Props> = ({
  lead,
  proposal,
  actions,
  documentUrl,
  documentKey,
  documentStatusConfiguration,
  documentStatusImpactAnalysis,
  handleToggleTermsModal,
  toggleDetails,
  isExpanded,
}) => {
  const impactAnalysis = proposal.ia;
  const offer = proposal.offer;
  const [isDownloadModalOpen, toggleDownloadModal] = React.useState(false);
  const [activeDocumentType, setActiveDocumentType] = React.useState<FlatDocumentType | undefined>(undefined);

  const onDownloadClick = (id: string, documentType: FlatDocumentType, documentStatus: DocumentStatus | undefined) => {
    setActiveDocumentType(documentType);
    toggleDownloadModal(true);

    if (documentStatus && documentStatus !== DocumentStatus.FAILED) {
      actions.startDocumentPolling(
        id,
        documentType,
      );
    }
  };

  const onDownloadModalClose = () => {
    actions.stopDocumentOfferPolling();
    toggleDownloadModal(false);
  };

  const onRegenerateDocumentClick = () => {
    toggleDownloadModal(true);
    actions.regenerateDocument(lead!.id, offer!.id, 'offer', activeDocumentType!);
  };

  const documentStatusMap: Record<FlatDocumentType, DocumentStatus | undefined> = {
    [FlatDocumentType.FLAT_CONFIGURATION]: documentStatusConfiguration,
    [FlatDocumentType.IMPACT_ANALYSIS]: documentStatusImpactAnalysis,
    [FlatDocumentType.FLAT_OFFER]: undefined,
    [FlatDocumentType.FLAT_ORDER_CONFIRMATION]: undefined,
    [FlatDocumentType.CESSION]: undefined,
  };

  const documentStatus = activeDocumentType && documentStatusMap[activeDocumentType];

  /*
    @TODO Remove
    T.customerGuidance.purchase.details
    T.customerGuidance.purchase.flatOffer.product
    T.customerGuidance.purchase.flatOffer.label
    T.customerGuidance.purchase.flatOffer.documents.impactAnalysis.name
  */
  return (
    <div className={'c-guide-purchase-flat-offer'}>
      <div className={'c-guide-purchase-flat-offer__products'}>
        <div className={'c-guide-purchase-flat-offer__product'}>
          <div className={'c-guide-purchase-flat-offer__basic-info'}>
            <div className={'c-guide-purchase-flat-offer__header'}>
              <span>
                {offer && isSpecificFlatProductInOffer(offer, OfferProductType.FLAT_X)
                  ? I18n.t(T.customerGuidance.purchase.flatOffer.header)
                  : I18n.t(T.customerGuidance.purchase.flatOffer.headerFlatDirect)
                }
              </span>
              <span className={'c-guide-purchase-flat-offer__header-subtitle'}>
                {(I18n.t(T.customerGuidance.purchase.flatOffer.receivedDate, {
                  date: getFormattedDate(proposal.createdAt),
                }))}
              </span>
            </div>
            <GuidePurchaseFlatOfferSection
              title={(
                <span className={'c-guide-purchase-flat-offer__documents-title'}>
                  {I18n.t(T.customerGuidance.purchase.flatOffer.documents.title)}
                </span>
              )}
            >
              {impactAnalysis && (
                <DownloadLink
                  title={
                    <GuideTextWithTooltip
                      title={I18n.t(T.customerGuidance.purchase.tooltip.impactAnalysis.title)}
                      content={I18n.t(T.customerGuidance.purchase.tooltip.impactAnalysis.description)}
                    />
                  }
                  onDownload={() => onDownloadClick(
                    impactAnalysis.id,
                    FlatDocumentType.IMPACT_ANALYSIS,
                    documentStatusImpactAnalysis,
                  )}
                />
              )}
              {documentKey && offer ? (
                <DownloadLink
                  title={offer && isSpecificFlatProductInOffer(offer, OfferProductType.FLAT_X)
                    ? I18n.t(T.customerGuidance.purchase.flatOffer.document.name)
                    : I18n.t(T.customerGuidance.purchase.flatOffer.document.nameFlatDirect)
                  }
                  onDownload={() => onDownloadClick(
                    offer.id,
                    FlatDocumentType.FLAT_CONFIGURATION,
                    documentStatusConfiguration,
                  )}
                />
              ) : (
                <div className={'c-guide-purchase-flat-offer__no-offer'}>
                  {I18n.t(T.customerGuidance.purchase.flatOffer.documents.offerPrepared)}
                </div>
              )
              }
              {(impactAnalysis || documentKey) && (
                <Portal target={PortalExit.ROOT}>
                  <ModalDocumentDownload
                    isOpen={isDownloadModalOpen}
                    status={documentStatus || DocumentStatus.PENDING}
                    onModalClose={onDownloadModalClose}
                    loadingText={I18n.t(T.customerGuidance.documents.statusMessage.pending)}
                    errorText={<GuideDocumentErrorModal onRegenerateClick={onRegenerateDocumentClick} />}
                    linkText={I18n.t(T.customerGuidance.documents.statusMessage.created.clickHere)}
                    createdText={I18n.t(T.customerGuidance.documents.statusMessage.created.toOpenFlat)}
                    fileUrl={documentUrl!}
                  />
                </Portal>
              )}
            </GuidePurchaseFlatOfferSection>
            <GuidePurchaseFlatOfferSection
              title={(
                <GuideTextWithTooltip
                  title={offer && isSpecificFlatProductInOffer(offer, OfferProductType.FLAT_X)
                    ? I18n.t(T.customerGuidance.purchase.tooltip.sonnenFlat.title)
                    : I18n.t(T.customerGuidance.purchase.tooltip.sonnenFlatDirect.title)}
                  content={I18n.t(T.customerGuidance.purchase.tooltip.sonnenFlat.description)}
                />
              )}
            >
              {createConfigurationBasicDetails(proposal)}
            </GuidePurchaseFlatOfferSection>
          </div>
          <div className={'c-guide-purchase-flat-offer__accordion'}>
            <GuidePurchaseAccordion
              toggleDetails={toggleDetails}
              isExpanded={isExpanded}
            >
              <GuidePurchaseProduct
                subtitle={createConfigurationDescription(proposal)}
              />
              <button
                onClick={handleToggleTermsModal}
                className={'c-guide-purchase-flat-offer__button'}
                type={'button'}
              >
                {I18n.t(T.customerGuidance.purchase.flatOffer.moreInfo)}
              </button>
            </GuidePurchaseAccordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export const GuidePurchaseFlatOffer = connect(mapStateToProps, mapDispatchToProps)(GuidePurchaseFlatOfferComponent);
