import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

import { isFlatDirectConfiguration } from '+app/+guide/+acceptance/store/+acceptance.helper';
import { insertIf } from '+app/utils/array.util';
import { Configuration } from '+guide/store/types';
import { KeyValueRowTheme } from '+shared/components/KeyValueRow';
import { ConfigurationHelper } from '+shared/helpers/configuration.helper';

export const factorizeConfBasicInfo = (configuration: Configuration) => {
  const currency = ConfigurationHelper.getCurrencyForMarket(configuration.market);

  return [
    // Usage Allowance per year
    {
      title: I18n.t(T.customerGuidance.purchase.usageAllowance),
      value: `${ConfigurationHelper.formatMeasurementEnergy(configuration.usageAllowancePerYear)}/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
    },
    // Basic fee
    {
      title: isFlatDirectConfiguration(configuration) ?
      `${I18n.t(T.customerGuidance.purchase.flatOffer.monthlyFee)}:`
      : `${I18n.t(T.customerGuidance.purchase.flatOffer.basicFee)}:`,
      value: `${ConfigurationHelper.formatMeasurementPrice({ value: configuration.baseFeeGrossPerMonth?.value }, currency)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.month)}`,
    },
    // @TODO add Montly prepayment for 12 months: 'expected_bill_amount_gross_per_year' from PE
  ];
};

export const factorizeMonthlyPrepaymentInfo = (conf: Configuration) => {
  const currency = ConfigurationHelper.getCurrencyForMarket(conf.market);

  return [
    // Monthly pre-payment
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.monthlyPrepayment)}:`,
      value: `${ConfigurationHelper.formatMeasurementPrice(conf.initialAdvancePaymentGrossPerMonth, currency)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.month)}`,
      type: KeyValueRowTheme.DARK,
    },
    // Excess energy
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.excessEnergy)}:`,
      value: `${ConfigurationHelper.formatMeasurementEnergy(conf.powerPlant!.expectedExcessEnergyPerYear)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
      type: KeyValueRowTheme.LIGHT,
    },
    // Excess tariff
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.excessTariff)}:`,
      value: ConfigurationHelper.formatPricePerKwh(conf.excessTariffGrossPerKwh, currency, { asMinorUnit: true }),
      type: KeyValueRowTheme.LIGHT,
    },
  ];
};

export const factorizeExpectedCashbackInfo = (conf: Configuration) => {
  const currency = ConfigurationHelper.getCurrencyForMarket(conf.market);

  return [
    // Expected cashback
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.expectedCashback)}:`,
      value: `${ConfigurationHelper.formatMeasurementPrice(conf.powerPlant!.expectedCashbackGrossPerYear, currency)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
      type: KeyValueRowTheme.DARK,
    },
    // Expected unused free usage allowance
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.expectedUnusedFreeUsageAllowance)}:`,
      value: `${ConfigurationHelper.formatMeasurementEnergy(conf.powerPlant!.expectedUnusedUsageAllowancePerYear)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
      type: KeyValueRowTheme.LIGHT,
    },
    // Cashback rate
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.cashbackRate)}:`,
      value: ConfigurationHelper.formatPricePerKwh(conf.cashbackTariffGrossPerKwh, currency, { asMinorUnit: true }),
      type: KeyValueRowTheme.LIGHT,
    },
  ];
};

export const factorizeVppBonusInfo = (conf: Configuration) => {
  const currency = ConfigurationHelper.getCurrencyForMarket(conf.market);

  return [
    // VPP bonus
    ...insertIf(
      !!conf.powerPlant.guaranteedVppBonusGranted,
      {
        title: I18n.t(T.customerGuidance.purchase.flatOffer.vppBonus),
        value: conf.powerPlant.guaranteedVppBonusGranted && conf.powerPlant.guaranteedVppBonusGrossPerYear
          ? `${I18n.t(T.customerGuidance.purchase.flatOffer.minimum)} `
          + `${ConfigurationHelper.formatMeasurementPrice(conf.powerPlant.guaranteedVppBonusGrossPerYear, currency)}`
          + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`
          : '',
        type: KeyValueRowTheme.DARK,
      },
    ),
  ];
};
