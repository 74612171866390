import { Data, MergedData } from '@coolio/json-api';
import { CountryCode } from '@sonnen/shared-web';

export enum BackupDeviceType {
  NONE = 'none',
  PROTECT = 'protect',
  BACKUPBOX = 'backupbox',
}

export enum BatteryControllerType {
  SPREE = 'spree',
  EATON = 'eaton',
}

export enum BatteryStatus {
  INSTALLED = 'installed',
  REGISTERED = 'registered',
  DISCARDED = 'discarded',
}

export type BatteryAssetStatus = {
  id: string;
  attributes: {
    assetStatus: BatteryStatus;
  }
};

type ProductAttributes = {
  batteryWithDisplay: boolean;
  cellTechnology: string;
  color: string;
  family: string;
  hardwareGenaration: string;
  hardwareGeneration: string;
  hardwareVersion: string;
  inverterType: string;
  name: string;
  productCode: string;
  productLine: string;
  quoteNameDe: string;
  quoteNameEn: string;
  salesforceProductId: string;
};

type ProductData = Data<ProductAttributes>;
type Product = MergedData<ProductData>;

/** documents */
export interface DocumentAttributes {
  title: string;
  language: string[];
  targetGroup: string;
  salesRegion: string[];
  articleNumber: string;
  kdNumber: number;
  spree: boolean;
  fileName: string;
  url: string;
}

export type DocumentError = string | Record<string, any> | undefined;
export type DocumentData = Data<DocumentAttributes>;
export type Document = MergedData<DocumentData>;

/** battery data */
// TODO: Update this interface. It's not up to date
export type BatteryAttributes = Partial<{
  articleNumber: string;
  serialNumber: string,
  installerName: string,
  installerStreet: string,
  installerPostalCode: string,
  installerCity: string,
  installerState: string,
  installerCountry: CountryCode,
  installerCompany: string;
  installerPhone: string,
  installerEmail: string,
  installerAccountName: string,
  installationDate: string,
  installationStreet: string,
  installationPostalCode: string,
  installationCity: string,
  installationState: string,
  installationCountryCode: CountryCode,
  batteryCapacity: number,
  batteryModules: number,
  batteryInverterDesignPower: number,
  hardwareVersion: string,
  softwareVersion: string,
  batteryChargeCycles: number,
  controllerType: BatteryControllerType,
  backupPowerBuffer: number,
  backupDeviceType: BackupDeviceType,
  warrantyPeriod: string,
  heaterConnectionStatus: boolean,
  heaterMaxTemperature: number,
  backupNominalPower: number,
  lastPowerOutageAt: string,
  cellType: string | null,
  timeZone: string | null,
  assetStatus: string,
  color: string,
  display: string,
  lastMeasurementAt: string,
  online: boolean,
  pvGridFeedInLimit: number,
  pvPeakPower: number,
  product: Product | null;
  smartSocketsEnabled: boolean,
}>;

export type BatteryData = Data<BatteryAttributes>;
export type Battery = MergedData<BatteryData>;

export type BatteryUnitAttributes = Partial<{
  serialNumber: string;
  salesforceComponentId: string;
  prevComponentId: string | null;
  activationDate: string;
  current: boolean;
}>;

export type BatteryUnitData = Data<BatteryUnitAttributes>;
export type BatteryUnit = MergedData<BatteryUnitData>;
export type BatteryUnits = BatteryUnit[];

export enum BatteryStatusKey {
  LATEST_BATTERY_SPECIAL_STATUS = 'latest_battery_special_status',
}

export interface BatteryStatusAttributes {
  key: BatteryStatusKey;
  value: string;
  timestamp: string;
}

export type BatteryStatusData = Data<BatteryStatusAttributes>;
export type BatteryStatusHistory = MergedData<BatteryStatusData>;

export interface BatteryStatusHistoryFilters {
  startAt: string;
  endAt: string;
  key: BatteryStatusKey;
}
