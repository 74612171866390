import { T } from '@sonnen/shared-i18n/customer';
import {
  DefaultParagraph,
  PageHeadline,
} from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { isFlatOfferAccepted } from '+app/+guide/+purchase/store/+purchase.selectors';
import { GuideActions } from '+app/+guide/store/+guide.actions';
import { getCessionFailed, getCessionStatus, getCessionStatusQueryStatus } from '+app/+guide/store/+guide.selectors';
import { getGuideSyncQueryStatus, isSonnenLead } from '+app/+guide/store/+lead.selectors';
import { getPath, getPaths } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { AppLoader } from '+app/shared/components';
import { Hint } from '+app/shared/store/sync/types/hint.interface';
import { StoreState } from '+app/store/store.interface';
import { GuidePhase, GuidePreviewNav } from '+guide/components';
import { mapActions } from '+utils/redux';
import { GuidePurchaseTabs } from '../';

import './GuidePurchase.component.scss';

const mapStateToProps = (state: StoreState) => ({
  isSonnenLead: isSonnenLead(state),
  guideSyncQueryStatus: getGuideSyncQueryStatus(state),
  cessionStatusQueryStatus: getCessionStatusQueryStatus(state),
  isFlatOfferAccepted: isFlatOfferAccepted(state),
  cessionStatus: getCessionStatus(state),
  cessionFailed: getCessionFailed(state),
});

const mapDispatchToProps = mapActions({
  goToDiscovery: () => push(getPaths().GUIDE_DISCOVERY),
  goToSetup: () => push(getPaths().GUIDE_SETUP),
  setActiveTab: GuideActions.setPhaseActiveTab,
  getCessionStatus: GuideActions.getCessionStatus,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

export const GuidePurchaseComponent: React.FC<Props> = ({
  actions,
  isSonnenLead,
  guideSyncQueryStatus,
  cessionStatusQueryStatus,
  cessionStatus,
  isFlatOfferAccepted,
}) => {
  // @TODO Rethink the conditions
  const isCessionStatusLoaded = (isFlatOfferAccepted && !cessionStatus)
    ? !(cessionStatusQueryStatus.pending)
    : true;
  const isLoading = !(guideSyncQueryStatus.success && isCessionStatusLoaded);
  const guideLoaderHint: Hint = {
    icon: 'icofont-contract',
    headline: I18n.t(T.customerGuidance.loader.headline),
    description: I18n.t(T.customerGuidance.loader.description),
  };

  return (
    <div className={'c-guide-purchase'}>
      <PageHeadline smallGap={true}>
        {I18n.t(T.customerGuidance.purchase.headline)}
      </PageHeadline>
      {!isLoading ? (
        <>
          <div className={'c-guide-purchase__subheadline'}>
            <DefaultParagraph>
              {I18n.t(T.customerGuidance.purchase.subheadline)}
            </DefaultParagraph>
          </div>
          <GuidePhase
            pathFactory={(step?: string) => step
              ? getPath(RouteName.GUIDE_PURCHASE_STEP, [step])
              : getPath(RouteName.GUIDE_PURCHASE)
          }>
            <GuidePurchaseTabs />
          </GuidePhase>
          {isSonnenLead ? (
            <GuidePreviewNav
              prevPhase={actions.goToDiscovery}
              nextPhase={actions.goToSetup}
            />
          ) : (
            <GuidePreviewNav
              nextPhase={actions.goToSetup}
            />
          )}
        </>
      ) : (
        <AppLoader hint={guideLoaderHint}/>
      )}
    </div>
  );
};

export const GuidePurchase = connect(mapStateToProps, mapDispatchToProps)(GuidePurchaseComponent);
