import { createSelector } from 'reselect';

import { StoreState } from '+app/store/store.interface';
import { getStatus } from '+app/utils/query.util';
import { QueryStatus } from '+query/query.state';
import {
  ContractState,
  GET_CONTRACT_DATA_QUERY,
  GET_CONTRACT_DOCUMENT_URL_QUERY,
  GET_CONTRACT_DOCUMENTS_QUERY,
  GET_CONTRACT_FILES_REQUEST_DATA_QUERY,
  GET_CONTRACT_INVOICES_REQUEST_DATA_QUERY,
  GET_STATISTICS_DATA_QUERY,
  TERMINATE_CONTRACT_QUERY,
} from './contract.state';
import {
  hasContractDeliveryStarted,
  hasVpp,
  isContractType,
  isContractUsageAllowanceActivated,
  isEnergyDocumentRequestDataValid,
  isTariffType,
  selectCurrentContract,
} from './helpers/contract.helpers';
import {
  Contract,
  ContractTypes,
  TariffTypes,
} from './types/contract.interface';

export const getContractState = (state: StoreState): ContractState => state.contract;

export const getCurrentSiteContractData = createSelector(
  getContractState,
  ({ contracts }) => selectCurrentContract(contracts),
);

export const getCurrentSiteContractId = createSelector(
  getCurrentSiteContractData,
  contractData => contractData?.id,
);

export const getAllContracts = createSelector(
  getContractState,
  state => state.contracts,
);

export const getSonnenFlatStatisticsData = createSelector(
  getContractState,
  (state) => state.flatContractStatistics,
);

export const getLastYearSonnenFlatStatistics = createSelector(
  getContractState,
  (state) => state.flatContractStatistics?.lastYear,
);

export const getDeliveryStartAt = (contract?: Contract) =>
  contract && contract.deliveryStartAt;

export const isProductMigrationAvailable = createSelector(
  getCurrentSiteContractData,
  (contract) => Boolean(contract?.migrationViaCp),
);

export const checkDeliveryStartAt = createSelector(
  getCurrentSiteContractData,
  getDeliveryStartAt,
);

export const getHasVpp = createSelector(
  getCurrentSiteContractData,
  (contract) => hasVpp(contract),
);

export const hasSonnenEnergyItTariff = createSelector(
  getCurrentSiteContractData,
  (contract) => isTariffType(contract, TariffTypes.SONNEN_ENERGY_IT));
export const hasSonnenEnergiaItContract = createSelector(
  getCurrentSiteContractData,
  (contract) => isContractType(contract, ContractTypes.SONNEN_FLAT_IT));

export const hasContractTypeSonnenFlatHome = createSelector(
  getCurrentSiteContractData,
  (contract) => isContractType(contract, ContractTypes.SONNEN_FLAT_HOME),
);

export const hasContractTypeSonnenFlatX = createSelector(
  getCurrentSiteContractData,
  (contract) => isContractType(contract, ContractTypes.SONNEN_FLAT_X),
);

export const hasSonnenStromContract = createSelector(
  getCurrentSiteContractData,
  (contract) => isContractType(contract, ContractTypes.SONNEN_STORM),
);

export const hasSonnenEnergyContract = createSelector(
  getCurrentSiteContractData,
  (contract) => isContractType(contract, ContractTypes.SONNEN_ENERGY),
);

export const hasSonnenConnectAuContract = createSelector(
  getCurrentSiteContractData,
  (contract) => isContractType(contract, ContractTypes.SONNEN_CONNECT_AU),
);

export const hasSonnenControlContract = createSelector(
  getCurrentSiteContractData,
  (contract) => isContractType(contract, ContractTypes.SONNEN_CONTROL),
);

export const getAppointedEnergyRate = createSelector(
  getCurrentSiteContractData,
  (contract) => contract && contract.appointedEnergyRate,
);

export const getContractFilesRequest = createSelector(
  getContractState,
  state => state.energyDocuments.contractFilesRequestData,
);

export const getContractInvoicesRequest = createSelector(
  getContractState,
  state => state.energyDocuments.contractInvoicesRequestData,
);

export const getContractDataQueryStatus = createSelector(
  getContractState,
  state => getStatus(state[GET_CONTRACT_DATA_QUERY]),
);

export const getContractDataQueryErrorCount = createSelector(
  getContractState,
  state => state[GET_CONTRACT_DATA_QUERY].errorCount || 0,
);

export const getContractStatisticsQueryStatus = createSelector(
  getContractState,
  state => getStatus(state[GET_STATISTICS_DATA_QUERY]),
);

export const getContractStatisticsQueryErrorCount = createSelector(
  getContractState,
  state => state[GET_STATISTICS_DATA_QUERY].errorCount || 0,
);

// TODO: Cleanup that selectors and align it to existing in live-state / battery
export const getContractFilesQueryStatus = createSelector(
  getContractState,
  state => getStatus(state[GET_CONTRACT_FILES_REQUEST_DATA_QUERY]),
);

export const getContractInvoicesQueryStatus = createSelector(
  getContractState,
  state => getStatus(state[GET_CONTRACT_INVOICES_REQUEST_DATA_QUERY]),
);

export const hasContractDataQueryFailed = createSelector(
  getContractState,
  state => state[GET_CONTRACT_DATA_QUERY].status === QueryStatus.FAILURE,
);

export const isContractDataQueryPending = createSelector(
  getContractState,
  state => state[GET_CONTRACT_DATA_QUERY].status === QueryStatus.PENDING,
);

export const hasContractStatisticsQueryFailed = createSelector(
  getContractState,
  state => state[GET_STATISTICS_DATA_QUERY].status === QueryStatus.FAILURE,
);

export const getUsageAllowanceActivationDate = createSelector(
  getCurrentSiteContractData,
  contract => contract && contract.usageAllowanceActivatedOn,
);

export const isDeliveryStartAndUsageAllowancePast = createSelector(
  getCurrentSiteContractData,
  contract => contract && hasContractDeliveryStarted(contract) && isContractUsageAllowanceActivated(contract),
);

export const getMeterInstallationId = createSelector(
  getCurrentSiteContractData,
  contract => contract?.meterInstallationId,
);

export const getCurrentSiteContractType = createSelector(
  getCurrentSiteContractData,
  contract => contract?.contractType,
);

export const getCurrentSiteTariffType = createSelector(
  getCurrentSiteContractData,
  contract => contract?.tariffType,
);

export const getCurrentSiteContractNumber = createSelector(
  getCurrentSiteContractData,
  contract => contract?.contractNumber,
);

export const getSalesforceContractId = createSelector(
  getCurrentSiteContractData,
  (state) => state?.salesforceContractId,
);

export const hasPreviousContractNumber = createSelector(
  getCurrentSiteContractData,
  contract => !!contract?.contractPreviousNumber,
);

export const getContractOrderedAtDate = createSelector(
  getCurrentSiteContractData,
  contract => contract?.orderedAt,
);

export const getContractCountryCode = createSelector(
  getCurrentSiteContractData,
  contract => contract?.shippingCountryCode,
);

export const getFinancialServiceProvider = createSelector(
  getCurrentSiteContractData,
  contract => contract?.financialServiceProvider,
);

export const getTerminationRequested = createSelector(
  getCurrentSiteContractData,
  contract => contract?.terminationRequested,
);

export const getTerminateContractQuery = createSelector(
  getContractState,
  contract => contract[TERMINATE_CONTRACT_QUERY],
);

export const getTerminateContractQueryStatus = createSelector(
  getTerminateContractQuery,
  query => getStatus(query),
);

export const getContractDocuments = createSelector(
  getContractState,
  state => state.documents,
);

export const getContractDocumentsQuery = createSelector(
  getContractState,
  state => state[GET_CONTRACT_DOCUMENTS_QUERY],
);

export const getContractDocumentsQueryStatus = createSelector(
  getContractDocumentsQuery,
  query => getStatus(query),
);

export const getContractDocumentUrlQuery = createSelector(
  getContractState,
  state => state[GET_CONTRACT_DOCUMENT_URL_QUERY],
);

export const getContractDocumentUrlQueryStatus = createSelector(
  getContractDocumentUrlQuery,
  query => query.status,
);

export const getContractDocumentUrl = createSelector(
  getContractState,
  state => state.documentUrl,
);

export const getContractSelectedDocumentId = createSelector(
  getContractState,
  state => state.selectedDocumentId,
);
