import { Document, Proposal } from '+app/+guide/store/types';
import { GuidePurchaseHardwareOffer } from '+guide/+purchase/components';
import { GuidePurchaseFlatOffer } from '+guide/+purchase/containers';
import * as React from 'react';

interface Props {
  proposal: Proposal;
  hardwareOffers?: Document[];
  handleToggleTermsModal: () => void;
  toggleDetails?: () => void;
  isExpanded?: boolean;
}

export const GuidePurchaseProposalContent: React.FC<Props> = ({
  proposal,
  handleToggleTermsModal,
  hardwareOffers,
  toggleDetails,
  isExpanded,
}) => (
  <div
    className={'c-guide-purchase-step-accept-offer__offer-group'}
    key={`offer-${proposal.id}`}
  >
    {hardwareOffers && !!hardwareOffers.length && proposal.offer && (
      <GuidePurchaseHardwareOffer
        hardwareOffers={hardwareOffers}
        offer={proposal.offer}
      />
    )}
    <GuidePurchaseFlatOffer
      proposal={proposal}
      handleToggleTermsModal={handleToggleTermsModal}
      toggleDetails={toggleDetails}
      isExpanded={isExpanded}
    />
  </div>
);
