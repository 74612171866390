import { ResponseError } from '+app/shared/network/network.interface';
import { ActionsUnion, createAction } from '+utils/redux';
import { DocumentStatus } from '@sonnen/shared-web';
import { CessionDocumentStatusAttributes, Document, FlatDocumentType, ImpactAnalysis, Lead, LeadCollection, Offer } from './types';
import { Partner } from './types/partner.interface';

export enum GUIDE_ACTIONS {
  SET_PHASE_ACTIVE_TAB = '[+Guide] SET_PHASE_ACTIVE_TAB',
  SET_AUTH_TOKEN = '[+Guide] SET_AUTH_TOKEN',
  SET_VERIFIED_AUTH_TOKEN = '[+Guide] SET_VERIFIED_AUTH_TOKEN',
  SET_LEAD = '[+Guide] SET_LEAD',
  SET_LEAD_ID = '[+Guide] SET_LEAD_ID',
  SET_PARTNER = '[+Guide] SET_PARTNER',
  SET_OFFERS = '[+Guide] SET_OFFERS',
  SET_OFFER_ACCEPTED_STATUS = '[+Guide] SET_OFFER_ACCEPTED_STATUS',
  SET_HARDWARE_OFFERS = '[+Guide] SET_HARDWARE_OFFERS',
  SET_FLAT_OFFERS = '[+Guide] SET_FLAT_OFFERS',
  SET_FLAT_CONFIGURATIONS = '[+Guide] SET_FLAT_CONFIGURATIONS',
  SET_IMPACT_ANALYSIS_LIST = '[+Guide] IMPACT_ANALYSIS_LIST',
  SET_ORDER_CONFIRMATION_STATUS = '[+Guide] SET_ORDER_CONFIRMATION_STATUS',
  SET_CESSION_DOCUMENT_STATUS = '[+Guide] SET_CESSION_DOCUMENT_STATUS',

  SET_TAX_OFFICE_REGISTRATION = '[+Guide] SET_TAX_OFFICE_REGISTRATION',
  SET_BNETZA_REGISTRATION = '[+Guide] SET_BNETZA_REGISTRATION',

  GET_GUIDE_DATA = '[+Guide] GET_GUIDE_DATA',
  GET_HARDWARE_OFFER_FILE = '[+Guide] GET_HARDWARE_OFFER_FILE',
  SET_DOCUMENT_URL = '[+Guide] SET_DOCUMENT_URL',
  CLEAR_DOCUMENT_URL = '[+Guide] CLEAR_DOCUMENT_URL',
  START_DOCUMENT_POLLING = '[+Guide] START_DOCUMENT_POLLING',
  STOP_DOCUMENT_POLLING = '[+Guide] STOP_DOCUMENT_POLLING',
  REGENERATE_DOCUMENT = '[+Guide] REGENERATE_DOCUMENT',

  GET_CESSION_DOCUMENT = '[+Guide] GET_CESSION_DOCUMENT',
  GET_CESSION_STATUS = '[+Guide] GET_CESSION_STATUS',
  SET_CESSION_DOCUMENT = '[+Guide] SET_CESSION_DOCUMENT',
  START_CESSION_DOCUMENT_POLLING = '[+Guide] START_CESSION_DOCUMENT_POLLING',
  STOP_CESSION_DOCUMENT_POLLING = '[+Guide] STOP_CESSION_DOCUMENT_POLLING',
  SET_CESSION_STATUS = '[+Guide] SET_CESSION_STATUS',
  START_CESSION_STATUS_POLLING = '[+Guide] START_CESSION_STATUS_POLLING',
  STOP_CESSION_STATUS_POLLING = '[+Guide] STOP_CESSION_STATUS_POLLING',
  SET_CESSION_FAILURE_STATUS = '[+Guide] SET_CESSION_FAILURE_STATUS',

  REQUIRE_IDENTITY_VERIFICATION = '[+Guide] REQUIRE_IDENTITY_VERIFICATION',
  VERIFY_IDENTITY = '[+Guide] VERIFY_IDENTITY',
  VERIFY_IDENTITY_ERROR = '[+Guide] VERIFY_IDENTITY_ERROR',
  IDENTITY_VERIFIED = '[+Guide] IDENTITY_VERIFIED',
  REVOKE_TOKEN = '[+Guide] REVOKE_TOKEN',
  LOGOUT = '[+Guide] LOGOUT',
}

export const GuideActions = {
  setPhaseActiveTab: (activeTab: number) => createAction(
    GUIDE_ACTIONS.SET_PHASE_ACTIVE_TAB,
    { activeTab },
  ),
  setAuthToken: (token: string) => createAction(
    GUIDE_ACTIONS.SET_AUTH_TOKEN,
    { token },
  ),
  setVerifiedAuthToken: (token: string | undefined, leadId?: string) => createAction(
    GUIDE_ACTIONS.SET_VERIFIED_AUTH_TOKEN,
    { token, leadId },
  ),
  setLead: (lead: Lead) => createAction(
    GUIDE_ACTIONS.SET_LEAD,
    { lead },
  ),
  setLeadId: (leadCollection: LeadCollection) => createAction(
    GUIDE_ACTIONS.SET_LEAD_ID,
    { leadCollection },
  ),
  setPartner: (partner: Partner) => createAction(
    GUIDE_ACTIONS.SET_PARTNER,
    { partner },
  ),
  setOffers: (offers: Offer[]) => createAction(
    GUIDE_ACTIONS.SET_OFFERS,
    { offers },
  ),
  setOfferAcceptedStatus: (offerId: string) => createAction(
    GUIDE_ACTIONS.SET_OFFER_ACCEPTED_STATUS,
    { offerId },
  ),
  setHardwareOffers: (offerId: string, documents: Document[]) => createAction(
    GUIDE_ACTIONS.SET_HARDWARE_OFFERS,
    { offerId, documents },
  ),
  setFlatOffers: (offerId: string, documents: Document[]) => createAction(
    GUIDE_ACTIONS.SET_FLAT_OFFERS,
    { offerId, documents },
  ),
  setFlatConfigurations: (offerId: string, documents: Document[]) => createAction(
    GUIDE_ACTIONS.SET_FLAT_CONFIGURATIONS,
    { offerId, documents },
  ),
  setImpactAnalysisList: (impactAnalysisList: ImpactAnalysis[]) => createAction(
    GUIDE_ACTIONS.SET_IMPACT_ANALYSIS_LIST,
    { impactAnalysisList },
  ),

  // @TODO: Why MergedData<TaxOfficeRegistrationCompletedData> is not working?
  setTaxOfficeRegistration: (data: any) => createAction(
    GUIDE_ACTIONS.SET_TAX_OFFICE_REGISTRATION,
    { data },
  ),
  setBNetzARegistration: (data: any) => createAction(
    GUIDE_ACTIONS.SET_BNETZA_REGISTRATION,
    { data },
  ),
  getGuideData: () => createAction(
    GUIDE_ACTIONS.GET_GUIDE_DATA,
  ),
  getHardwareOfferFile: (
    offerId: string,
    documentId: string,
    ) => createAction(
      GUIDE_ACTIONS.GET_HARDWARE_OFFER_FILE,
      { offerId, documentId },
  ),
  setOrderConfirmationStatus: (status: DocumentStatus) => createAction(
    GUIDE_ACTIONS.SET_ORDER_CONFIRMATION_STATUS,
    { status },
  ),
  setCessionDocumentStatus: (status: DocumentStatus) => createAction(
    GUIDE_ACTIONS.SET_CESSION_DOCUMENT_STATUS,
    { status },
  ),
  getCessionDocument: () => createAction(
    GUIDE_ACTIONS.GET_CESSION_DOCUMENT,
  ),
  getCessionStatus: () => createAction(
    GUIDE_ACTIONS.GET_CESSION_STATUS,
  ),
  setCessionDocument: (cessionDocUrl: string) => createAction(
    GUIDE_ACTIONS.SET_CESSION_DOCUMENT,
    { cessionDocUrl },
  ),
  startCessionDocumentPolling: () => createAction(
    GUIDE_ACTIONS.START_CESSION_DOCUMENT_POLLING,
  ),
  stopCessionDocumentPolling: () => createAction(
    GUIDE_ACTIONS.STOP_CESSION_DOCUMENT_POLLING,
  ),
  setCessionStatus: ({ status, timestamps }: CessionDocumentStatusAttributes) => createAction(
    GUIDE_ACTIONS.SET_CESSION_STATUS,
    { status, timestamps },
  ),
  startCessionStatusPolling: () => createAction(
    GUIDE_ACTIONS.START_CESSION_STATUS_POLLING,
  ),
  stopCessionStatusPolling: () => createAction(
    GUIDE_ACTIONS.STOP_CESSION_STATUS_POLLING,
  ),
  setCessionFailureStatus: (cessionFailed: boolean) => createAction(
    GUIDE_ACTIONS.SET_CESSION_FAILURE_STATUS,
    { cessionFailed },
  ),
  regenerateDocument: (
    leadId: string,
    resourceId: string,
    resourceType: 'offer',
    documentType: FlatDocumentType,
  ) => createAction(
    GUIDE_ACTIONS.REGENERATE_DOCUMENT,
    { leadId, resourceId, resourceType, documentType },
  ),
  setDocumentUrl: (url: string) => createAction(
    GUIDE_ACTIONS.SET_DOCUMENT_URL,
    { url },
  ),
  clearDocumentUrl: () => createAction(GUIDE_ACTIONS.CLEAR_DOCUMENT_URL),
  startDocumentPolling: (
    offerId: string,
    flatOfferType: FlatDocumentType,
  ) => createAction(
    GUIDE_ACTIONS.START_DOCUMENT_POLLING,
    { offerId, flatOfferType },
  ),
  stopDocumentPolling: () => createAction(GUIDE_ACTIONS.STOP_DOCUMENT_POLLING),

  requireIdentityVerification: () => createAction(GUIDE_ACTIONS.REQUIRE_IDENTITY_VERIFICATION),
  verifyIdentity: (zipCode: string, persisted: boolean) => createAction(
    GUIDE_ACTIONS.VERIFY_IDENTITY,
    { zipCode, persisted },
  ),
  verifyIdentityError: (error: ResponseError) => createAction(
    GUIDE_ACTIONS.VERIFY_IDENTITY_ERROR,
    { error },
  ),
  identityVerified: () => createAction(GUIDE_ACTIONS.IDENTITY_VERIFIED),
  revokeToken: (leadId: string) => createAction(
    GUIDE_ACTIONS.REVOKE_TOKEN,
    { leadId },
  ),
  logout: () => createAction(GUIDE_ACTIONS.LOGOUT),
};

export type GuideActions = ActionsUnion<typeof GuideActions>;
