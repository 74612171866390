import { prepareProposal } from '+app/+guide/+purchase/containers/GuidePurchaseStepAcceptOffer/GuidePurchaseStepAcceptOffer.helper';
import {
  getPrimaryFlatConfigurationDocumentStatus,
  getPrimaryImpactAnalysisDocumentStatus,
} from '+app/+guide/+purchase/store/+offer.selectors';
import { GuideDocumentErrorModal, GuideTableRow } from '+app/+guide/components';
import { GuideActions } from '+app/+guide/store/+guide.actions';
import {
  getDocumentUrl,
  getGuideOfferCollection,
  getImpactAnalysisList,
} from '+app/+guide/store/+guide.selectors';
import { getGuideLead, getGuideLeadPartnerName, getGuideSyncQueryStatus, getLeadStatus } from '+app/+guide/store/+lead.selectors';
import { getPath } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { StoreState } from '+app/store/store.interface';
import { GuideDocument, GuideDocumentsSection } from '+guide/+documents/components';
import { getFlatOrderConfirmationDocumentStatus, isFlatOrderConfirmed } from '+guide/+purchase/store/+purchase.selectors';
import { getCessionStatus, getGuideAcceptedOffer, getGuideCessionDocumentStatus } from '+guide/store/+guide.selectors';
import { CessionStatus, FlatDocumentType, LeadStatusName } from '+guide/store/types';
import { getFormattedDate } from '+legacy/helpers/dates';
import { mapActions } from '+utils/redux';

import { AppLoader } from '+app/shared/components';
import { Hint } from '+app/shared/store/sync/types/hint.interface';
import { T } from '@sonnen/shared-i18n/customer';
import {
  Card,
  DocumentStatus,
  Icon,
  InfoBanner,
  MediaQuery,
  ModalDocumentDownload,
  PageHeadline,
} from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import { last } from 'lodash';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './GuideDocuments.component.scss';

const mapStateToProps = (state: StoreState) => ({
  lead: getGuideLead(state),
  offers: getGuideOfferCollection(state),
  offerAcceptedStatus: getLeadStatus(LeadStatusName.FLAT_OFFER_ACCEPTED)(state),
  acceptedOffer: getGuideAcceptedOffer(state),
  partnerName: getGuideLeadPartnerName(state),
  documentUrl: getDocumentUrl(state),
  documentStatusConfiguration: (id: string) => getPrimaryFlatConfigurationDocumentStatus(state)({ offerId: id }),
  documentStatusImpactAnalysis: (id: string) => getPrimaryImpactAnalysisDocumentStatus(state)({ impactAnalysisId: id }),
  impactAnalysisList: getImpactAnalysisList(state),
  orderConfirmedStatus: getLeadStatus(LeadStatusName.FLAT_ORDER_CONFIRMED)(state),
  orderConfirmationDocumentStatus: getFlatOrderConfirmationDocumentStatus(state),
  cessionDocumentStatus: getGuideCessionDocumentStatus(state),
  guideSyncQueryStatus: getGuideSyncQueryStatus(state),
  cessionStatus: getCessionStatus(state),
  flatOrderConfirmed: isFlatOrderConfirmed(state),
});

const mapDispatchToProps = mapActions({
  startDocumentPolling: GuideActions.startDocumentPolling,
  stopDocumentOfferPolling: GuideActions.stopDocumentPolling,
  regenerateFlatOfferDocument: GuideActions.regenerateDocument,
  goToAcceptance: (offerId: string) => push(getPath(RouteName.GUIDE_ACCEPTANCE_ID, [offerId])),
  getCessionStatus: GuideActions.getCessionStatus,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

export const GuideDocumentsComponent: React.FC<Props> = ({
  lead,
  offers,
  partnerName,
  actions,
  documentUrl,
  documentStatusConfiguration,
  documentStatusImpactAnalysis,
  offerAcceptedStatus,
  acceptedOffer,
  impactAnalysisList,
  orderConfirmedStatus,
  orderConfirmationDocumentStatus,
  cessionDocumentStatus,
  guideSyncQueryStatus,
  cessionStatus,
  flatOrderConfirmed,
}) => {
  React.useEffect(() => {
    actions.getCessionStatus();
  }, []);

  const guideLoaderHint: Hint = {
    icon: 'icofont-contract',
    headline: I18n.t(T.customerGuidance.loader.headline),
    description: I18n.t(T.customerGuidance.loader.description),
  };

  const proposals = acceptedOffer
    ? prepareProposal([acceptedOffer], impactAnalysisList, true)
    : prepareProposal(offers, impactAnalysisList);

  const offerLetters = proposals.reduce((obj, proposal, index) => ({
    ...obj,
    [proposal.id]: String.fromCharCode(65 + proposals.length - 1 - index),
  }), {});

  const [isDownloadModalOpen, toggleDownloadModal] = React.useState(false);
  const [activeDocument, setActiveDocument] = React.useState<GuideDocument | undefined>(undefined);

  const getDocumentStatus = () => {
    let documentStatus;
    switch (activeDocument?.documentType) {
      case FlatDocumentType.FLAT_CONFIGURATION:
        documentStatus = documentStatusConfiguration(activeDocument.id);
        break;
      case FlatDocumentType.FLAT_ORDER_CONFIRMATION:
        documentStatus = orderConfirmationDocumentStatus;
        break;
      case FlatDocumentType.CESSION:
        documentStatus = cessionDocumentStatus;
        break;
      case FlatDocumentType.IMPACT_ANALYSIS:
        documentStatus = documentStatusImpactAnalysis(activeDocument.id);
        break;
      default:
        documentStatus = DocumentStatus.PENDING;
    }
    return documentStatus ? documentStatus : DocumentStatus.PENDING;
  };

  const sections = proposals.map((proposal) => {
    const impactAnalysis = proposal.ia;
    const offer = proposal.offer;
    const doneBy = partnerName || '';

    const offerDocument: GuideDocument | undefined = offer && {
      id: offer.id,
      documentType: FlatDocumentType.FLAT_CONFIGURATION,
      name: `${I18n.t(T.customerGuidance.documents.offer)} ${offerLetters[proposal.id]}`,
      doneBy,
      date: getFormattedDate(last(offer.flatOffers)!.createdAt) || '',
    };

    const impactAnalysisDocument: GuideDocument | undefined = impactAnalysis && {
      id: impactAnalysis.id,
      documentType: FlatDocumentType.IMPACT_ANALYSIS,
      name: `${I18n.t(T.customerGuidance.documents.impactAnalysis)} ${offerLetters[proposal.id]}`,
      doneBy,
      date: getFormattedDate(impactAnalysis.createdAt) || '',
    };

    const cessionDocument: GuideDocument | undefined =
      (cessionStatus === CessionStatus.SIGNED && flatOrderConfirmed) ?
        {
          id: FlatDocumentType.CESSION,
          documentType: FlatDocumentType.CESSION,
          name: I18n.t(T.customerGuidance.documents.cession),
          doneBy: I18n.t(T.customerGuidance.contact.sonnen.eServices),
          date: '',
        }
        : undefined;

    const orderConfirmationDocument: GuideDocument | undefined =
      (orderConfirmedStatus && orderConfirmedStatus.isSet) ?
        {
          id: 'order-confirmation',
          documentType: FlatDocumentType.FLAT_ORDER_CONFIRMATION,
          name: I18n.t(T.customerGuidance.documents.orderConfirmation),
          doneBy: I18n.t(T.customerGuidance.contact.sonnen.eServices),
          date: getFormattedDate(orderConfirmedStatus.createdAt) || '',
        }
        : undefined;

    const isDefined = <T extends GuideDocument>(doc: T | undefined | false): doc is T =>
      doc !== undefined && doc !== false;
    const documents = [
      offerDocument,
      impactAnalysisDocument,
      cessionDocument,
      orderConfirmationDocument,
    ].filter(isDefined);

    return {
      acceptOffer: offer ? () => actions.goToAcceptance(offer.id) : undefined,
      documents,
    };
  });

  const onDownloadClick = (document: any) => {
    toggleDownloadModal(true);
    setActiveDocument(document);
    actions.startDocumentPolling(document.id, document.documentType);
  };

  const onDownloadModalClose = () => {
    actions.stopDocumentOfferPolling();
    toggleDownloadModal(false);
  };

  const onRegenerateDocument = () => {
    if (!activeDocument) {
      return;
    }

    if (activeDocument.documentType === FlatDocumentType.FLAT_CONFIGURATION) {
      toggleDownloadModal(true);
      actions.regenerateFlatOfferDocument(
        lead!.id,
        activeDocument.id,
        'offer',
        FlatDocumentType.FLAT_CONFIGURATION,
      );
    }
  };

  return (
    <>
      <PageHeadline>
        {I18n.t(T.customerGuidance.documents.headline)}
      </PageHeadline>
      {guideSyncQueryStatus.success ? (
        <>
          {sections.length ? (
            <>
              <Media query={MediaQuery.UP_SM}>
                <div className={'c-guide-documents__header'}>
                  <GuideTableRow
                    content={I18n.t(T.customerGuidance.documents.document)}
                    secondaryContent={I18n.t(T.customerGuidance.documents.sentOut)}
                    tertiaryContent={I18n.t(T.customerGuidance.documents.doneBy)}
                  />
                </div>
              </Media>
              <div>
                <Card
                  title={(
                    <div className={'c-guide-documents__title-section'}>
                      <div className={'c-guide-documents__title'}>
                        {I18n.t(T.customerGuidance.documents.yourConfiguration)}
                      </div>
                      {offerAcceptedStatus && (
                        <div className={'c-guide-documents__title-side'}>
                          <span className={'c-guide-documents__title-side-label'}>
                            {I18n.t(T.customerGuidance.documents.orderedAt)}
                          </span>
                          <span className={'c-guide-documents__title-side-date'}>
                            {getFormattedDate(offerAcceptedStatus.createdAt)}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                >
                  <div className={'c-guide-documents__sections'}>
                    {sections.map((section, i) => (
                      <div key={`section_${i}`}>
                        <GuideDocumentsSection
                          setActiveDocument={onDownloadClick}
                          documents={section.documents}
                          goToAcceptance={section.acceptOffer}
                          accepted={!!offerAcceptedStatus}
                        />
                        {(i !== sections.length - 1) && (
                          <hr className={'c-guide-documents__separator'} />
                        )}
                      </div>
                    ))}
                  </div>
                </Card>
              </div>
            </>
          ) : (
            <div className={'c-guide-documents__banner'}>
              <InfoBanner
                icon={<Icon.DocumentUfo />}
                title={I18n.t(T.customerGuidance.documents.placeholder.headline)}
                subtitle={I18n.t(T.customerGuidance.documents.placeholder.description)}
              />
            </div>
          )}
        </>
      ) : (
        <AppLoader hint={guideLoaderHint}/>
      )}

      {/* @TODO Change modal to show createdText based on document name */}
      {activeDocument && (
        <ModalDocumentDownload
          isOpen={isDownloadModalOpen}
          status={getDocumentStatus()}
          onModalClose={onDownloadModalClose}
          loadingText={I18n.t(T.customerGuidance.documents.statusMessage.pending)}
          errorText={<GuideDocumentErrorModal onRegenerateClick={onRegenerateDocument} />}
          linkText={I18n.t(T.customerGuidance.documents.statusMessage.created.clickHere)}
          createdText={I18n.t(T.customerGuidance.documents.statusMessage.created.toDownloadDocument)}
          fileUrl={documentUrl!}
        />
      )}
    </>
  );
};

export const GuideDocuments = connect(mapStateToProps, mapDispatchToProps)(GuideDocumentsComponent);
