import { T } from '@sonnen/shared-i18n/customer';
import { PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { ConfigurationHelper } from '+app/shared/helpers/configuration.helper';
import { GuideVatInfo } from '+guide/components';
import { Configuration } from '+guide/store/types/configuration.interface';
import { GuideAcceptanceDetailsLine } from '../../components';
import { isFlatDirectConfiguration } from '../../store/+acceptance.helper';
import { factorizeConfDetails } from './GuideAcceptanceOfferDetails.helper';

import './GuideAcceptanceOfferDetails.component.scss';

interface Props {
  configuration: Configuration | undefined;
  isFlatXOffer: boolean;
}

export const GuideAcceptanceOfferDetails: React.FC<Props> = ({
  configuration,
  isFlatXOffer,
}) => configuration ? (
  <div className={'c-guide-acceptance-offer-details'}>
    <PageSubheadline noBorder={true} classNames={'c-guide-acceptance-offer-details__header'}>
      {isFlatXOffer
        ? I18n.t(T.customerGuidance.acceptance.flatOfferDetails.headline)
        : I18n.t(T.customerGuidance.acceptance.flatOfferDetails.headlineFlatDirect)
      }
    </PageSubheadline>
    <div className={'c-guide-acceptance-offer-details__img'} />
    <div className={'c-guide-acceptance-offer-details__data'}>
      <GuideAcceptanceDetailsLine
        isMainValue={true}
        name={`${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.usageAllowance)}:`}
        value={`${ConfigurationHelper.formatMeasurementEnergy(configuration.usageAllowancePerYear)}/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`}
      />
      <GuideAcceptanceDetailsLine
        isMainValue={true}
        name={isFlatDirectConfiguration(configuration) ?
          `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.monthlyFee)}:`
          : `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.basicFee)}:`}
        value={`${ConfigurationHelper.formatMeasurementPrice({ value: configuration.baseFeeGrossPerMonth.value },
          ConfigurationHelper.getCurrencyForMarket(configuration.market))}`
          + `/${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.month)}`}
      />
      <div className={'c-guide-acceptance-offer-details__separator'} />
      {factorizeConfDetails(configuration).map(({ name, value, isLight }) =>
        <GuideAcceptanceDetailsLine key={name} name={name} value={value} isLight={isLight} />,
      )}
      <div className={'c-guide-acceptance-offer-details__separator'} />
      <div className={'c-guide-acceptance-offer-details__info'}>
        <GuideVatInfo
          rate={ConfigurationHelper.formatTaxRate(configuration.valueAddedTax)}
        />
      </div>
    </div>
  </div>
) : (
    <div className={'c-guide-acceptance-offer-details'}>
      {I18n.t(T.customerGuidance.acceptance.flatOfferDetails.configurationMissing)}
    </div>
  );
