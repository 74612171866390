import { GuideDocumentEntry } from '+guide/components';
import { FlatDocumentType } from '+guide/store/types';
import { T } from '@sonnen/shared-i18n/customer';
import {
  Button,
  ButtonSize,
  ButtonTheme,
} from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './GuideDocumentsSection.component.scss';

export interface GuideDocument {
  id: string;
  name: string;
  doneBy: string;
  date: string;
  documentType: FlatDocumentType;
}

interface Props {
  documents: GuideDocument[];
  accepted: boolean;
  goToAcceptance?: () => void;
  setActiveDocument: (document: GuideDocument) => void;
}

export const GuideDocumentsSection: React.FC<Props> = ({
  documents,
  goToAcceptance,
  accepted,
  setActiveDocument,
}) => (
  <div className={'c-guide-documents-section'}>
    {documents.map((document) => (
      <div key={`documents_${document.name}`}>
        <div className={'c-guide-documents-section__row'}>
          <GuideDocumentEntry
            name={document.name}
            date={document.date}
            author={document.doneBy}
            onDownload={() => setActiveDocument(document)}
          />
        </div>
      </div>
    ))}
    <div className={'c-guide-documents-section__button'}>
      {!accepted && goToAcceptance && (
        <Button
          label={I18n.t(T.customerGuidance.documents.orderProcess)}
          size={ButtonSize.SECONDARY}
          theme={ButtonTheme.OUTLINE}
          onClick={goToAcceptance}
        />
      )}
    </div>
  </div>
);
