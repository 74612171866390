import { AxisOrientation, GridLines } from '@kanva/charts';
import { AreaChartView, AxisView, ChartGridView, LineChartView } from '@kanva/charts-react';
import { rgba, TextAlign, Visibility } from '@kanva/core';
import { TextView, View as ViewComponent } from '@kanva/react';
import { T } from '@sonnen/shared-i18n/customer';
import { CHART_FONT, DefaultParagraph, EnergyFlowSeriesKey, SharedChartColors } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { QueryState } from '+app/shared/store/query/query.state';
import { FullWidthKanva } from '+components';
import { useAnalysisDayChart } from '../../containers/AnalysisKanvaProvider/AnalysisDayChartProvider';
import { useAnalysis } from '../../containers/AnalysisProvider/AnalysisProvider.context';
import { AnalysisLineChartTooltip } from '../AnalysisLineChartTooltip';
import { AreaSeriesStyle, lineSeriesStyle, Views } from './AnalysisAreaChart.helper';
import { layout } from './AnalysisAreaChart.mobile.layout';
import { gridPaint, labelPaint, xAxisLabelPaint, yAxisLabelPaint } from './AnalysisAreaChart.styles';

import './AnalysisAreaChart.mobile.component.scss';

interface AnalysisAreaChartMobile {
  chartHeight: number;
  queryStatus: QueryState;
  isVisible: (key: EnergyFlowSeriesKey) => Visibility;
}

export const AnalysisAreaChartMobile: React.FC<AnalysisAreaChartMobile> = ({
  chartHeight,
  queryStatus,
  isVisible,
}) => {
  const { containers, isChartZoomed, handleMount } = useAnalysisDayChart();

  const yLabel = yAxisLabelPaint.setFont({
    ...CHART_FONT,
    fontSize: 8,
  });

  const isLoading = queryStatus.pending;

  const batteryCareTooltipPosition = {
    left: '54px',
    bottom: '45px',
  };

  return (
    <div className={'m-c-analysis-area-chart'}>
      <FullWidthKanva
        height={chartHeight}
        isLoading={isLoading}
      >
        <ViewComponent
          layoutParams={layout.wrapper}
          border={{ bottom: 1 }}
          borderColor={rgba(SharedChartColors.DARK, .1)}
        >
          <ChartGridView
            layoutParams={layout.grid}
            dataContainer={containers?.default}
            gridLines={GridLines.HORIZONTAL}
            style={{ paint: gridPaint }}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.PRODUCTION_POWER}
            visibility={isVisible(EnergyFlowSeriesKey.PRODUCTION_POWER)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.PRODUCTION_POWER]}
            onMount={handleMount}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.CONSUMPTION_POWER}
            visibility={isVisible(EnergyFlowSeriesKey.CONSUMPTION_POWER)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.CONSUMPTION_POWER]}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.DIRECT_USAGE_POWER}
            visibility={isVisible(EnergyFlowSeriesKey.DIRECT_USAGE_POWER)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.DIRECT_USAGE_POWER]}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.GRID_FEEDIN}
            visibility={isVisible(EnergyFlowSeriesKey.GRID_FEEDIN)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.GRID_FEEDIN]}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.GRID_PURCHASE}
            visibility={isVisible(EnergyFlowSeriesKey.GRID_PURCHASE)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.GRID_PURCHASE]}
            onMount={handleMount}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.percentage}
            dataSeries={EnergyFlowSeriesKey.BATTERY_USOC}
            visibility={isVisible(EnergyFlowSeriesKey.BATTERY_USOC)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.BATTERY_USOC]}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER}
            visibility={isVisible(EnergyFlowSeriesKey.PRODUCTION_POWER)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER}
            visibility={isVisible(EnergyFlowSeriesKey.CONSUMPTION_POWER)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]}
          />
        </ViewComponent>

        <ViewComponent
          layoutParams={layout.lineWrapper}
          id={Views.LINE_WRAPPER}
        >
          <LineChartView
            id={Views.BATTERY_CARE_LINE}
            layoutParams={layout.batteryCare}
            dataContainer={containers?.default}
            dataSeries={'batteryCare'}
            visibility={Visibility.VISIBLE}
            style={lineSeriesStyle}
          >
            <TextView
              layoutParams={layout.lineLabel}
              text={I18n.t(T.yourDay.chart.labels.batteryCare)}
              textPaint={labelPaint}
            />
          </LineChartView>
        </ViewComponent>

        <AxisView
          id={Views.X_AXIS}
          layoutParams={layout.xAxis}
          dataContainer={containers?.default}
          orientation={AxisOrientation.HORIZONTAL}
          style={{
            labelPaint: xAxisLabelPaint,
            wrapLabelsOnEdge: !isChartZoomed,
          }}
        />
        <AxisView
          id={Views.Y_AXIS_LEFT}
          layoutParams={layout.yAxisLeft}
          dataContainer={containers?.default}
          orientation={AxisOrientation.VERTICAL}
          style={{
            labelPaint: yLabel.clone()
              .setTextAlign(TextAlign.LEFT),
          }}
        />
        <AxisView
          id={Views.Y_AXIS_RIGHT}
          layoutParams={layout.yAxisRight}
          dataContainer={containers?.percentage}
          orientation={AxisOrientation.VERTICAL}
          style={{
            labelPaint: yLabel.clone()
              .setTextAlign(TextAlign.RIGHT),
          }}
        />
      </FullWidthKanva>

      <div
        className={'c-analysis-area-chart__line-chart-tooltip-wrapper'}
        style={{ ...batteryCareTooltipPosition }}
      >
        <AnalysisLineChartTooltip
          tooltip={(
            <DefaultParagraph>
              {I18n.t(T.analysis.dayChart.batteryCare.infoBubble.explanation)}
              <a
                className={'c-analysis-area-chart__line-chart-tooltip-link'}
                href={I18n.t(T.analysis.dayChart.batteryCare.infoBubble.linkUrl)}
                target={'_blank'}
              >
                {I18n.t(T.analysis.dayChart.batteryCare.infoBubble.linkLabel)}
              </a>
              {'.'}
            </DefaultParagraph>
          )}
        />
      </div>
    </div>
  );
};
