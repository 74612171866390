import { Data, MergedData } from '@coolio/json-api';
import { EnergyUnit } from '@sonnen/shared-web';

import { ConfigurationAddress, ConfigurationGeometryPoint, ConfigurationMarket, ConfigurationMeasurement } from '+app/shared/types/configuration.interface';
import { CurrencyUnit, OtherUnit, PeakPowerUnit } from '+utils/unit.util';

export enum ConfigurationStatus {
  PREVIEW = 'preview',
  OFFER = 'offer',
}

export enum ProductType {
  FLATX = 'flatx',
  FLATDIRECT = 'flatdirect',
}

export interface ConfigurationResponsePvSystem {
  peakPower: ConfigurationMeasurement<PeakPowerUnit.KWP>;
  inclination?: ConfigurationMeasurement<OtherUnit.DEGREE>;
  orientation?: ConfigurationMeasurement<OtherUnit.DEGREE>;
  specificYieldPerYear?: ConfigurationMeasurement<EnergyUnit.KWH_PER_KWP>;
  commissioningDate: string;
  systemName?: string;
  systemKey?: string;
}

export interface ConfigurationAttributes {
  market: ConfigurationMarket;
  product: ProductType;
  createdAt: string;
  productVersion: string;
  termsAndConditions: string;
  status: ConfigurationStatus;
  usageAllowancePerYear: ConfigurationMeasurement<EnergyUnit.KWH>;
  excessTariffGrossPerKwh: ConfigurationMeasurement<CurrencyUnit.EUR>;
  cashbackTariffGrossPerKwh: ConfigurationMeasurement<CurrencyUnit.EUR>;
  initialAdvancePaymentGrossPerMonth: ConfigurationMeasurement<CurrencyUnit.EUR>;
  deliveryAddress: ConfigurationAddress;
  photovoltaicSystem: {
    peakPower: ConfigurationMeasurement<PeakPowerUnit.KWP>;
    inclination: ConfigurationMeasurement<OtherUnit.DEGREE>;
    orientation: ConfigurationMeasurement<OtherUnit.DEGREE>;
    commissioningDate: string;
    location: ConfigurationMeasurement<OtherUnit.GEOMETRY, ConfigurationGeometryPoint>;
    specificYieldPerYear: ConfigurationMeasurement<EnergyUnit.KWH_PER_KWP>;
    expectedProductionPerYear: ConfigurationMeasurement<EnergyUnit.KWH>;
    feedInTariffPerKwh: ConfigurationMeasurement<CurrencyUnit.EUR>;
    subSystems?: ConfigurationResponsePvSystem[];
  };
  battery: {
    capacityGross: ConfigurationMeasurement<EnergyUnit.KWH>;
  };
  expectedElectricityConsumption: {
    totalConsumptionPerYear: ConfigurationMeasurement<EnergyUnit.KWH>;
  };
  powerPlant: {
    estimatedAutarky: ConfigurationMeasurement<OtherUnit.PERCENT>;
    expectedSelfConsumptionPerYear: ConfigurationMeasurement<EnergyUnit.KWH>;
    expectedFeedInPerYear: ConfigurationMeasurement<EnergyUnit.KWH>;
    expectedGridConsumptionPerYear: ConfigurationMeasurement<EnergyUnit.KWH>;
    expectedExcessEnergyPerYear: ConfigurationMeasurement<EnergyUnit.KWH>;
    expectedBonusPerYear: ConfigurationMeasurement<CurrencyUnit.EUR>;
    guaranteedVppBonusGrossPerYear?: ConfigurationMeasurement<CurrencyUnit.EUR>;
    expectedCashbackGrossPerYear: ConfigurationMeasurement<CurrencyUnit.EUR>;
    expectedUnusedUsageAllowancePerYear: ConfigurationMeasurement<EnergyUnit.KWH>;
    guaranteedVppBonusGranted: boolean;
  };
  valueAddedTax: ConfigurationMeasurement<OtherUnit.PERCENT>;
  baseFeeGrossPerMonth: ConfigurationMeasurement<CurrencyUnit.EUR>;
}

export type ConfigurationData = Data<ConfigurationAttributes>;
export type Configuration = MergedData<ConfigurationData>;
